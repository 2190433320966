import React from 'react';
import {
    Card,
    CardContent,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Typography,
    Divider,
} from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ScreeningInfo = ({ screeningInfo }) => {
    return (
        <div>
            {/* Screening Info Card */}
            <Card style={{ paddingBottom: '0px', paddingTop: '3px', marginBottom: '10px' }}>

                <CardContent>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Screening Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <strong>Screened On:</strong> {global._DateField(screeningInfo.screenedOn) || 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <strong>Screened By:</strong> {screeningInfo.screenedByName || 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <strong>Screening Notes:</strong> {screeningInfo.screeningNotes || 'N/A'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>

            {/* Interview Details Card */}
            <Card style={{ paddingBottom: '0px', paddingTop: '3px' }}>

                <CardContent>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Interview Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <strong>Interviewed On:</strong> {global._DateField(screeningInfo.interviewedOn) || 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <strong>Interviewed By:</strong> {screeningInfo.interviewedByName || 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <strong>Interview Notes:</strong> {screeningInfo.interviewNotes || 'N/A'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </div>
    );
};

export default ScreeningInfo;