/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useStyles from "../../contacts/styles";
import {
    Button,
    Paper,
    Modal,
    Box,
    Typography
} from "@mui/material";
import ApplicationScheduleForm from "./components/ScheduleForm";
import { saveContact } from '../../../api/contact';
import { saveHistory } from '../../../api/employees';
import { saveSchedule } from '../../../api/schedules';
import { saveContactSchedule } from '../../../api/schedules';

//TODO


// need to add this AddAvailableEmployeeSchedule



const AvailabilityInfo = ({ handlePrev, contactData, historyData, availabilityData, setAvailabilityData }) => {

    const classes = useStyles();

    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [availabilityData, setAvailabilityData] = useState({});

    const [fieldErrors, setFieldErrors] = useState({
        rate: null,
        ratePeriod: null
    });

    // const handleChange = (e) => {
    //     const { name, value, type, checked } = e.target;
    //     setFormData(prev => ({
    //         ...prev,
    //         [name]: type === 'checkbox' ? checked : value
    //     }));
    // };


    const handlePrevClick = () => {
        // setHistoryData(generalData);
        handlePrev();
    };

    const handleSubmitClick = async () => {
        try {
            const contactId = await handleSaveContact();
            await handleSaveHistory(contactId);
            const scheduleIds = await handleSaveAvailability()
            await handleSaveContactSchedules(scheduleIds, contactId)
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error handling submit:', error);
        }
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleSaveContact = async () => {
        const contactId = -1;

        try {
            const result = await saveContact(contactId, contactData);
            console.log('Contact saved successfully:', result);
            return result.id
        } catch (error) {
            console.error('Error saving contact:', error);
        }
    };

    const fieldStyle = {
        marginBottom: '8px',
    };

    const handleSaveHistory = async (contactId) => {

        try {
            const result = await saveHistory(contactId, historyData);
            console.log('History saved successfully:', result);
            return result.id
        } catch (error) {
            console.error('Error saving History:', error);
        }
    };

    const handleSaveAvailability = async () => {

        try {
            const result = await saveSchedule(availabilityData);
            return result.result
        } catch (error) {
            console.error('Error saving availability:', error);
        }
    };

    const handleSaveContactSchedules = async (scheduleIds, contactId) => {

        try {
            const result = await saveContactSchedule(scheduleIds, contactId);
            return result.result
        } catch (error) {
            console.error('Error saving ContactSchedules:', error);
        }
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            minHeight: '94vh',
            padding: '10px',
            backgroundColor: '#f5f5f5',
        }}>
            <Paper style={{
                padding: 30,
                maxWidth: '700px',
                width: '100%',
                minHeight: '94vh',
            }}>
                <form noValidate autoComplete="off" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
                    <ApplicationScheduleForm
                        defaultScheduleData={availabilityData}
                        setScheduleData={setAvailabilityData}
                    />
                </form>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                }}>
                    <Button variant="text" color="primary" onClick={handlePrevClick}>
                        Previous
                    </Button>

                    <Button variant="text" color="primary" onClick={handleSubmitClick}>
                        Submit
                    </Button>
                </div>

                <Modal
                    open={isModalOpen}
                    // onClose={handleCloseModal}
                    aria-labelledby="thank-you-modal-title"
                    aria-describedby="thank-you-modal-description"
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 300,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Typography id="thank-you-modal-title" variant="h6" component="h2">
                            Submission Successful
                        </Typography>
                        <Typography id="thank-you-modal-description" sx={{ mt: 2 }}>
                            Your submission has been sent. Thank you!
                        </Typography>
                        {/* <Button onClick={handleCloseModal} sx={{ mt: 2 }}>
                            Close
                        </Button> */}
                    </Box>
                </Modal>
            </Paper>
        </div>
    );
};

export default AvailabilityInfo;

// const AvailabilityInfo = ({ handlePrev, contactData, historyData }) => {
//     const classes = useStyles();
//     const [availabilityData, setAvailabilityData] = useState([{
//         Id: -1,
//         startTime: '',
//         date: new Date('1970-01-01T00:00:00Z'),
//         endTime: '',
//         days: []
//     }]);

//     const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

//     const handlePrevClick = () => {
//         handlePrev();
//     };

//     const handleSubmitClick = async () => {
//         try {
//             const contactId = await handleSaveContact();
//             await handleSaveHistory(contactId);
//             const scheduleIds = await handleSaveAvailability();
//             await handleSaveContactSchedules(scheduleIds, contactId);

//             // Open the modal after successful submission
//             setIsModalOpen(true);
//         } catch (error) {
//             console.error('Error handling submit:', error);
//         }
//     };

//     const handleSaveContact = async () => {
//         const contactId = -1;

//         try {
//             const result = await saveContact(contactId, contactData);
//             console.log('Contact saved successfully:', result);
//             return result.id;
//         } catch (error) {
//             console.error('Error saving contact:', error);
//         }
//     };

//     const handleSaveHistory = async (contactId) => {
//         try {
//             const result = await saveHistory(contactId, historyData);
//             console.log('History saved successfully:', result);
//             return result.id;
//         } catch (error) {
//             console.error('Error saving History:', error);
//         }
//     };

//     const handleSaveAvailability = async () => {
//         try {
//             const result = await saveSchedule(availabilityData);
//             return result.result;
//         } catch (error) {
//             console.error('Error saving availability:', error);
//         }
//     };

//     const handleSaveContactSchedules = async (scheduleIds, contactId) => {
//         try {
//             const result = await saveContactSchedule(scheduleIds, contactId);
//             return result.result;
//         } catch (error) {
//             console.error('Error saving ContactSchedules:', error);
//         }
//     };

//     const handleCloseModal = () => {
//         setIsModalOpen(false);
//     };
//     return (
//         <div style={{
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'flex-start',
//             minHeight: '94vh',
//             padding: '10px',
//             backgroundColor: '#f5f5f5',
//         }}>
//             <Paper style={{
//                 padding: 30,
//                 maxWidth: '700px',
//                 width: '100%',
//                 minHeight: '94vh',
//             }}>
//                 <form noValidate autoComplete="off" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
//                     <ApplicationScheduleForm
//                         setScheduleData={setAvailabilityData}
//                     />
//                 </form>
//                 <div style={{
//                     display: 'flex',
//                     justifyContent: 'space-between',
//                     marginTop: '20px',
//                 }}>
//                     <Button variant="text" color="primary" onClick={handlePrevClick}>
//                         Previous
//                     </Button>

//                     <Button variant="text" color="primary" onClick={handleSubmitClick}>
//                         Submit
//                     </Button>
//                 </div>
//             </Paper>
//             <Modal
//                 open={isModalOpen}
//                 onClose={handleCloseModal}
//                 aria-labelledby="thank-you-modal-title"
//                 aria-describedby="thank-you-modal-description"
//             >
//                 <Box
//                     sx={{
//                         position: "absolute",
//                         top: "50%",
//                         left: "50%",
//                         transform: "translate(-50%, -50%)",
//                         width: 300,
//                         bgcolor: "background.paper",
//                         boxShadow: 24,
//                         p: 4,
//                     }}
//                 >
//                     <Typography id="thank-you-modal-title" variant="h6" component="h2">
//                         Submission Successful
//                     </Typography>
//                     <Typography id="thank-you-modal-description" sx={{ mt: 2 }}>
//                         Your submission has been sent. Thank you!
//                     </Typography>
//                     <Button onClick={handleCloseModal} sx={{ mt: 2 }}>
//                         Close
//                     </Button>
//                 </Box>
//             </Modal>
//         </div>
//     );
// };

// export default AvailabilityInfo;
