import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import React from 'react';
import {
    CLIENT_CONTACT_LIST, CLIENT_DETAILS,
    CLIENT_DETAIL_PROGRAM,
    CLIENT_DETAIL_PROGRAM_ComfortHealth,
    CLIENT_DETAIL_PROGRAM_ComfortHealth_SAVE,
    CLIENT_DETAIL_PROGRAM_FROMCLIENT, CLIENT_DETAIL_PROGRAM_Light,
    CLIENT_DETAIL_PROGRAM_Light_SAVE, CLIENT_DETAIL_PROGRAM_P3,
    CLIENT_DETAIL_PROGRAM_P3_SAVE,
    CLIENT_EDIT_PROGRAM_LIST, CLIENT_HISTORY_LIST, CLIENT_LIST, CLIENT_NAMES,
    CLIENT_NOTES_LIST,
    CLIENT_NOTE_REMOVE, CLIENT_PROGRAM_DETAIL,
    CLIENT_PROGRAM_DOCUMENT_EDIT, CLIENT_PROGRAM_LIST,
    CLIENT_PROGRESS_LIST,
    CLIENT_SCHEDULE_LIST,
    CLIENT_MONTHLY_PROGRESS_LIST,
    GET_TODO_LIST,
    TODO_REMOVE, TODO_SAVE,
    CMS_SCHEDULES_DETAILS, CONTACT_CMS_LIST,
    GET_CLIENT_PROGRAM_SERVICE,
    GET_CLIENT_SCHEDULE_APPOINTMENT, GET_CLIENT_SCHEDULE_PROFESSIONAL, GET_CLIENT_SCHEDULE_SERVICE,
    GET_SCHEDLE_FROM_PROVIDER, NON_RELATED_CONTACT_CMS_LIST,
    REMOVE_CLIENT,
    YESHIVAS_LIST,
    REMOVE_SCHEDULE_PROVIDER,
    CLIENT_PROGRAM_DOCUMENT_UPLOAD,
    CLIENT_SCHEDULE_GET_APPOINTMENT,
    clientFileTemplate,
    CLIENT_OUTSTANDING_NOTES,
    GET_CLIENT_MISSION_LIST,
    GET_CLIENT_MISSION_DETAIL,
    SAVE_CLIENT_MISSION,
    DELETE_CLIENT_MISSION,
    SAVE_CLIENT_MISSION_SERVICE,
    REMOVE_CLIENT_MISSION_SERVICE,
    GET_CLIENT_MISSION_SERVICE_LIST,
    SAVE_CLIENT_MISSION_OBJECTIVE,
    SAVE_CLIENT_MISSION_OBJECTIVE_COMPLETE,
    SAVE_CLIENT_MISSION_COMPLETE,
    CLIENT_MISSION_SERVICE_MONTHLY_OBJECTIVES,
    CLIENT_MISSION_ALL_MONTHLY_OBJECTIVES,
    CLIENT_SERVICE_MONTH_DETAIL,
    SAVE_CLIENT_MISSION_MONTHLY_OBJECTIVES,
    GET_TIMESHEET_CLIENT_LIST,
    GET_CLIENT_INFO,
    DISMISS_PROGRESS_TODO,
    MEARGEN_NOTES,
    MEARGENIM_WITH_MONTHLY_LIST,
    CLIENT_GENERAL_NOTES
} from "../constants";
import { weekNames } from "../components/data/clients";
import { dateTime } from "../_helpers/datetime";

function replaceNull(someObj, replaceValue = "***") {
    const replacer = (key, value) =>
        String(value) === "null" || String(value) === "undefined"
            ? replaceValue
            : value;
    //^ because you seem to want to replace (strings) "null" or "undefined" too

    return JSON.parse(JSON.stringify(someObj, replacer));
};

export let clients = () => {
    let clients_data = [];
    const clients = [];

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_LIST)
            .then(
                (data) => {
                    clients_data = data.result;
                    // global.log('data.result = ', data.result);
                    clients_data.forEach((item, index) => {
                        clients.push({
                            id: item.id,
                            last_name: item.contact.lastName,
                            first_name: item.contact.firstName,
                            fullName: item.contact.firstName + " " + item.contact.lastName,
                            firstNameHebrew: item.contact.firstNameHebrew,
                            lastNameHebrew: item.contact.lastNameHebrew,
                            legalName: item.contact.legalName,
                            phone1: item.contact.phone1,
                            yeshiva: item.yeshivaName,
                            age: dateTime.getAge(item.contact),
                            dob: item.contact.dob,
                            organizer: item.meargenName,
                            roshMeargen: item.roshMeargenName,
                            financialCoordinator: item.financialCoordinatorName,
                            employeeCount: item.employeeCount,
                            employees: item.employees.join(),
                            careManager: item.careManagerName,
                            hcbsCoordinator: item.hcbsCoordinatorName,
                            careManagerSupervisor: item.careManagerSupervisorName,
                            client_id: item.contactId,
                            city: item.contact.city,
                            father_name: item?.fathersName,
                            stage: item.stage,
                            neighborhood: item.contact.neighborhood,
                            comfortHealthStatus: item.comfortHealthStatus,
                            dateEnrolledComfort: item.dateEnrolledComfort,
                            dateApprovedComfort: item.dateApprovedComfort,
                            isPrevEnrolledInCH: item.isPrevEnrolledInCH,
                            comfortNote: item.comfortNote,
                            isSeeingTherapist: item.isSeeingTherapist,
                            pendedOn: item.pendedOn,
                            reactivatedOn: item.reactivatedOn,
                            sentDocsToParentsOn: item.sentDocsToParentsOn,
                            insuranceCompany: item.insuranceCompany,
                            sentBinderToComfortHealthOn: item.sentBinderToComfortHealthOn,
                            careManagerAssignedOn: item.careManagerAssignedOn,
                            intakeMeetingDate: item.intakeMeetingDate,
                            cans_CompletedOn: item.canS_CompletedOn,
                            crisis_CompletedOn: item.crisis_CompletedOn,
                            poc_CompletedOn: item.poC_CompletedOn,
                            comprehensiveAssessment_CompletedOn: item.comprehensiveAssessment_CompletedOn,
                            loc_CompletedOn: item.loC_CompletedOn,
                            packetSentOn: item.packetSentOn,
                            comfortHealthTimeRequirements: item.comfortHealthTimeRequirements,
                            sentIepToNewAgencyOn: item.sentIepToNewAgencyOn,
                            dateEnrolledP3: item.dateEnrolledP3,
                            dateApprovedP3: item.dateApprovedP3,
                            hoursPerWeekP3: item.hoursPerWeekP3,
                            dateEnrolledLight: item.dateEnrolledLight,
                            enrolledOn: item.enrolledOn,
                            disenrolledOn: item.disenrolledOn,
                            schedulesPendingFinancial: item.schedulesPendingFinancial,
                            uploadedInsuranceOn: item.contact.files.find(x => x.templateFileId === clientFileTemplate.InsuranceCard)?.uploadedOn,
                            uploadedConsentFormOn: item.contact.files.find(x => x.templateFileId === clientFileTemplate.ConsentForm)?.uploadedOn,
                            uploadedSedOn: item.contact.files.find(x => x.templateFileId === clientFileTemplate.Sed)?.uploadedOn,
                            uploadedIepOn: item.contact.files.find(x => x.templateFileId === clientFileTemplate.IepForP3)?.uploadedOn,

                            icon: <EditIcon className="icon" />,
                        });
                    });
                    resolve(clients);
                },
                (rejects) => {
                    global.log("rejects");
                    global.AjaxFailToast();
                    resolve([]);
                }
            );
    });
};

export const getClientInfo = (id) => {
    return new Promise((resolve, reject) => {
        global.fetchWrapper.get(GET_CLIENT_INFO + id)
            .then(
                (data) => {
                    resolve(data)
                },
                (reason) => {
                    reject(reason);
                }
            )
    })
}

export const getMeargenWithMonthlyStatus = () => {
    return new Promise((resolve, reject) => {
        global.fetchWrapper.get(MEARGENIM_WITH_MONTHLY_LIST)
            .then(
                (data) => {
                    resolve(data)
                },
                (reason) => {
                    reject(reason);
                }
            )
    })
}

export const getMeargenNotes = (id, startDate) => {
    let dateString = global.dateTime.getUSAFormattedDateString(startDate);
    return new Promise((resolve, reject) => {
        global.fetchWrapper.get(MEARGEN_NOTES + `startDate=${dateString}&meargenContactId=${id}`)
            .then(
                (data) => {
                    resolve(data)
                },
                (reason) => {
                    reject(reason);
                }
            )
    })
}

export const getClientNames = () => {
    return new Promise((resolve, reject) => {
        global.fetchWrapper.get(CLIENT_NAMES)
            .then(
                (data) => {
                    resolve(data)
                },
                (reason) => {
                    reject(reason);
                }
            )
    })
}

/**
 * 
 * @param {integer} clientId 
 * @returns 
 */
export const getClientMissionList = (clientId) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(GET_CLIENT_MISSION_LIST + clientId)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(data.result);
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    })
};

/**
 * 
 * @param {integer} missionId 
 * @returns
 */
export const getClientMissionDetail = (missionId) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(GET_CLIENT_MISSION_DETAIL + missionId)
            .then(
                (data) => {
                    if (!!data) {
                        resolve(data);
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    })
};

export const saveClientMission = (missionId, payload) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
            .post(SAVE_CLIENT_MISSION + missionId, payload)
            .then((data) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve(false);
                }
            },
                (rejects) => {
                    resolve(false);
                }
            );
    })
};

export const markMissionCompleted = (missionId) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
            .post(SAVE_CLIENT_MISSION_COMPLETE + missionId)
            .then((data) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve(false);
                }
            },
                (rejects) => {
                    resolve(false);
                }
            );
    })
};

export const deleteClientMission = (missionId, payload) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
            .delete(DELETE_CLIENT_MISSION + missionId)
            .then((data) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve(false);
                }
            },
                (rejects) => {
                    resolve(false);
                }
            );
    })
};

export const saveClientMissionObjective = (missionId, payload) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
            .post(SAVE_CLIENT_MISSION_OBJECTIVE + missionId, payload)
            .then((data) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve(false);
                }
            },
                (rejects) => {
                    resolve(false);
                }
            );
    })
};

export const markObjectiveComplete = (objectiveId) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
            .post(SAVE_CLIENT_MISSION_OBJECTIVE_COMPLETE + objectiveId)
            .then((data) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve(false);
                }
            },
                (rejects) => {
                    resolve(false);
                }
            );
    })
};

export const getClientMissionService = (clientId, month) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(GET_CLIENT_MISSION_SERVICE_LIST + clientId)
            .then(
                (data) => {
                    if (!!data) {
                        resolve(data.clientServices);
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    })
};

export const getTimesheetClientList = (month) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(GET_TIMESHEET_CLIENT_LIST + month)
            .then(
                (data) => {
                    if (data) {
                        resolve(data);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    resolve(false);
                }
            );
    });
}

export const saveClientMissionService = (missionId, clientServiceId) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(SAVE_CLIENT_MISSION_SERVICE + missionId + `/${clientServiceId}`)
            .then(
                (data) => {
                    if (data) {
                        resolve(data);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    resolve(false);
                }
            );
    });
};

export const removeClientMissionService = (missionId, clientServiceId) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(REMOVE_CLIENT_MISSION_SERVICE + missionId + `/${clientServiceId}`)
            .then(
                (data) => {
                    if (data) {
                        resolve(data.clientServices);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    resolve(false);
                }
            );
    });
};

// Client Monthly Services

export const getClientMissionServiceMonthlyObjective = (clientId, hebMonthYear) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_MISSION_SERVICE_MONTHLY_OBJECTIVES + clientId + "?hebMonthYear=" + hebMonthYear)
            .then(
                (data) => {
                    if (!!data) {
                        resolve(data.clientServiceMonths);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    })
};

export const getClientAllMonthlyObjectives = (clientSerivceId) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_MISSION_ALL_MONTHLY_OBJECTIVES + clientSerivceId)
            .then(
                (data) => {
                    if (!!data) {
                        resolve(data);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    })
};

export const getClientMonthlyServiceDetail = (clientId, hebMonthYear) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_SERVICE_MONTH_DETAIL + clientId + "?hebMonthYear=" + hebMonthYear)
            .then(
                (data) => {
                    if (!!data) {
                        resolve(data);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    })
};

export const saveClientMonthlyServiceObjectives = (payload, includeUpcoming) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
            .post(SAVE_CLIENT_MISSION_MONTHLY_OBJECTIVES + includeUpcoming, payload)
            .then((data) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve(false);
                }
            },
                (onError) => {
                    rejects(false);
                }
            );
    })
}

export let fetchOutstandingNotes = (startDate) => {
    let dateString = global.dateTime.getUSAFormattedDateString(startDate);
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_OUTSTANDING_NOTES + '?startDate=' + dateString)
            .then(
                (data) => {
                    if (data) {
                        resolve(data);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    resolve(false);
                }
            );
    });
};

export let removeClientFromList = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(REMOVE_CLIENT + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    global.AjaxFailToast();
                    resolve(false);
                }
            );
    });
};

export let getYeshivasList = () => {
    let yeshivasList = [];
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(YESHIVAS_LIST)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(data.result);
                    } else {
                        resolve([]);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    global.AjaxFailToast();
                    resolve([]);
                }
            );
    });
};

export let getClientDetails = (id) => {
    let detailOfclient;
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_DETAILS + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        // global.log('data.result =', data.result);
                        resolve(data.result);
                    } else {

                        resolve(data.responseCode);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    global.AjaxFailToast();
                    resolve(rejects);
                }
            );
    });
};

export const columnsClients = [
    { field: "last_name", headerName: "Last Name", width: 150 },
    { field: "first_name", headerName: "First Name", width: 150 },
    { field: "yeshiva", headerName: "Yeshiva", width: 200, sortable: false },
    { field: "age", headerName: "Age", width: 100, sortable: false },
    { field: "organizer", headerName: "מארגן", width: 150, sortable: false },
    { field: "city", headerName: "City", width: 100, sortable: false },
    {
        field: "father_name",
        headerName: "Father Name",
        width: 150,
        sortable: false,
    },
    {
        field: "",
        headerName: "Actions",
        width: 100,
        sortable: false,
        disableClickEventBubbling: true,
        renderCell: () => {
            return <EditIcon className="icon" />;
        },
    },
];

const isAllday = (inputStr) => {
    for (var i = 0; i < 7; i++)
        if (!inputStr.includes(i.toString())) return false;
    return true;
};

const isAllInclude = (inputStr, start, end) => {
    start = parseInt(start);
    end = parseInt(end);
    for (var i = start; i < end; i++)
        if (!inputStr.includes(i.toString())) return false;
    return true;
};

const isJoinedDay = (inputStr) => { };

export const getDaysOfWeekStr = (inputStr) => {
    if (!inputStr)
        return "";
    let strArray = inputStr.split(",");

    if (strArray.length === 0 || strArray[0] === "") return "-";
    if (isAllday(strArray)) return "Every Day";
    if (isAllInclude(strArray, strArray[0], strArray[strArray.length - 1]))
        return (
            weekNames[parseInt(strArray[0])] +
            " - " +
            weekNames[parseInt(strArray[strArray.length - 1])]
        );

    let destArray = [];
    for (var i = 0; i < strArray.length; i++) {
        destArray.push(weekNames[parseInt(strArray[i])]);
    }
    let destStr = destArray.join(", ");

    return destStr;
};

export let GetScheduleFromClientProviderId = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(GET_SCHEDLE_FROM_PROVIDER + id)
            .then(
                (data) => {

                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                    global.AjaxFailToast();
                    // rejects();
                    resolve(null);
                }
            );
    });
};

export let GetClientServiceSchedules = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(GET_CLIENT_SCHEDULE_SERVICE + id)
            .then(
                (data) => {
                    const formattedData = [];

                    data.forEach((item, index) => {
                        formattedData.push({
                            id: item.id,
                            clientProviderScheduleId: item.clientProviderScheduleId,
                            daysOfWeek: item.daysOfWeek,
                            startTime: item.timeFrom,
                            endTime: item.timeTo,
                            providerName: item.providerName,
                            providerContactId: item.providerContactId,
                            employeeId: item.employeeId,
                            note: item.note,
                            daysOfWeekStr: getDaysOfWeekStr(item.daysOfWeek),
                            rate: item.rate
                        });
                    });
                    resolve(formattedData);
                },
                (rejects) => {
                    global.log("rejects");
                    global.AjaxFailToast();
                    // rejects();
                    resolve([]);
                }
            );
    });
};

export let GetClientProfessionalsSchedules = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(GET_CLIENT_SCHEDULE_PROFESSIONAL + id)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                    global.AjaxFailToast();
                    // rejects();
                    resolve([]);
                }
            );
    });
};

export let GetClientAppointment = (clientScheduleId) => {
    if (!clientScheduleId)
        return;
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_SCHEDULE_GET_APPOINTMENT + clientScheduleId)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                    global.AjaxFailToast();
                    // rejects();
                    resolve([]);
                }
            );
    });
};

export let GetClientOneTimeAppointmentsSchedules = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(GET_CLIENT_SCHEDULE_APPOINTMENT + id)
            .then(
                (data) => {
                    resolve(data);
                },
                (rejects) => {
                    global.log("rejects");
                    global.AjaxFailToast();
                    // rejects();
                    resolve([]);
                }
            );
    });
};

export let RemoveScheduleProvider = (id) => {
    return global.fetchWrapper.post(REMOVE_SCHEDULE_PROVIDER + id).then(
        (response) => {
            // global.log("response", response);
        },
        (error) => {
            global.log("error", error);
            global.AjaxFailToast();
        }
    );
}

export let GetScheduleDetail = (id) => {
    let detailOfclient;
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CMS_SCHEDULES_DETAILS + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        // global.log('data.result =', data.result);
                        resolve(data.result);
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    // rejects();
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    });
};

export let contactCMSList = () => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CONTACT_CMS_LIST)
            .then(
                (data) => {
                    if (data.length) {
                        resolve(data);
                    } else {
                        resolve([]);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    global.AjaxFailToast();
                    // rejects();
                    resolve([]);
                }
            );
    });
};

export let nonRelatedContactCMSList = (clientId) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(NON_RELATED_CONTACT_CMS_LIST + clientId)
            .then(
                (data) => {
                    if (data.length) {
                        resolve(data);
                    } else {
                        resolve([]);
                    }
                },
                (rejects) => {
                    global.log("rejects");
                    global.AjaxFailToast({});
                    // rejects();
                    resolve([]);
                }
            );
    });
};

export let saveContactCMSList = (id) => { };

export let getClientProgramDetail = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_PROGRAM_LIST + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        let programs = [];
                        let programs_data = data.result;
                        !_.isEmpty(programs_data) &&
                            programs_data.forEach((item, index) => {
                                programs.push({
                                    id: item.id ? item.id : index,
                                    programName: item.program && item.program.programName,
                                    services: item.programService ? item.programService : "-",
                                    enrolled_on: item.enrolledOn
                                        ? global._DateField(item.enrolledOn)
                                        : "-",
                                    status: item.clientProgramStatus
                                        ? item.clientProgramStatus
                                        : "-",
                                    me: item.me ? item.me : "-",
                                    active: item.active ? item.active : "-",
                                    care_manager: item.contactId ? item.contactId : "-",
                                    bcba: item.bcba ? item.bcba : "-",
                                });
                            });
                        resolve(programs);
                    } else {
                        resolve([]);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve([]);
                }
            );
    });
};

export let getClientProgramServiceDetail = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(GET_CLIENT_PROGRAM_SERVICE + 17)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(data.result);
                    } else {
                        resolve([]);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve([]);
                }
            );
    });
};

export let getClientProgramTabDetail = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_PROGRAM_DETAIL + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(data.result);
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    });
};

export let removeClientNote = (id) => {
    return global.fetchWrapper.post(CLIENT_NOTE_REMOVE + id).then(
        (response) => {
            // global.log("response", response);
        },
        (error) => {
            global.log("error", error);
            global.AjaxFailToast();
        }
    );
};

export let getClientNotesList = (id, clientName) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_NOTES_LIST + id)
            .then(
                (data) => {
                    if (data.responseCode === 200 || !!data) {
                        let notes = [];
                        let notes_data = data.result ?? data;
                        !_.isEmpty(notes_data) &&
                            notes_data.forEach((item, index) => {
                                notes.push({
                                    type: item.type ? item.type : index,
                                    table: item.table,
                                    primaryId: item.primaryId ? item.primaryId : "",
                                    createdBy: global.isEmpty(item.account) ? "" : item.account.firstName + " " + item.account.lastName,
                                    createdById: global.isEmpty(item.account) ? 0 : item.account.id,
                                    createdOn: item.createdOn
                                        ? global._DateTimeField(new Date(`${item.createdOn}Z`))
                                        : "",
                                    creatorRole: item.creatorRole,
                                    noteDate: item.noteDate,
                                    title: item.title,
                                    noteDescription: item.noteDescription,
                                    nextStep: item.nextStep,
                                    id: item.id,
                                    noteTemplateId: item.noteTemplateId
                                });
                            });
                        resolve(notes);
                    } else {
                        resolve([]);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve([]);
                }
            );
    });
};

export const columnsClientProgram = [
    { field: "services", headerName: "Services", width: 150 },
    { field: "enrolled_on", headerName: "Enrolled On", width: 150 },
    { field: "status", headerName: "Status", width: 200, sortable: false },
    // { field: 'me', headerName: "Me'argen1", width: 200, sortable: false },
    // { field: 'care_manager', headerName: 'Care Manager', width: 100, sortable: false },
    // { field: 'bcba', headerName: 'BCBA', width: 150, sortable: false },
    { field: "active", headerName: "Active", width: 100, sortable: false },
];

export let getClientScheduleDetail = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_SCHEDULE_LIST + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(data.result);
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    });
};

export let getClientToDoDetail = (queryObj) => {
    let query = "?module=" + (queryObj.module ? queryObj.module : '') +
        "&primaryId=" + (queryObj.primaryId ? queryObj.primaryId : '') +
        "&userId=" + (queryObj.userId ? queryObj.userId : '') +
        "&filterBy=" + (queryObj.filterBy ? queryObj.filterBy : '') +
        "&showCompleted=" + (queryObj.showCompleted ? true : false);
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(GET_TODO_LIST + query)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        let todo = [];
                        let todo_data = data.result;
                        !_.isEmpty(todo_data) &&
                            todo_data.forEach((item, index) => {
                                let displayModule = item?.table === 'clients' ? 'talmid' : item?.table === 'employees' ? 'mamid' : '';
                                let module = item?.table === 'clients' ? 'client' : item?.table === 'employees' ? 'employee' : '';
                                todo.push({
                                    id: item.id ? item.id : index,
                                    title: item.title ? item.title : "-",
                                    description: item.description ? item.description : null,
                                    assignee: item.assignee
                                        ? `${item.assignee.firstName} ${item.assignee.lastName}`
                                        : "-",
                                    assignee_ContactId: item?.assignee_ContactId,
                                    startDate: item.startDate
                                        ? global._DateField(item.startDate)
                                        : null,
                                    dueDate: item.dueDate
                                        ? global._DateField(item.dueDate)
                                        : null,
                                    completedDate: item.completedDate
                                        ? global._DateField(item.completedDate)
                                        : null,
                                    creator: item?.creator?.firstName + " " + item?.creator?.lastName,
                                    creator_ContactId: item?.creator_ContactId,
                                    primaryId: item?.primaryId,
                                    primaryName: item?.primaryName + ' - ' + displayModule,
                                    module: module,
                                });

                            });
                        resolve(todo);
                    } else {
                        resolve([]);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve([]);
                }
            );
    });
};

// export let getClientGeneralNotes = (queryObj) => {
//     let query = "?table=" + (queryObj.table ? queryObj.table : '') +
//         "&primaryId=" + (queryObj.primaryId ? queryObj.primaryId : '') +
//         "&nocache=" + new Date().getTime();

//     return new Promise((resolve, reject) => {
//         console.log("In function")
//         global.fetchWrapper.get(
//             `${CLIENT_GENERAL_NOTES}${queryObj.table}/${queryObj.primaryId}`
//         )
//             .then((data) => {

//                 const notesData = Array.isArray(data) ? data : data.result;

//                 if (notesData && notesData.length > 0) {
//                     const notes = notesData.map((item, index) => ({
//                         id: item.id ? item.id : index,
//                         type: item.type ? item.type : "-",
//                         table: item.table ? item.table : "-",
//                         primaryId: item.primaryId,
//                         noteDate: item.noteDate ? global._DateField(item.noteDate) : null,
//                         title: item.title ? item.title : "-",
//                         noteDescription: item.noteDescription ? item.noteDescription : null,
//                         nextStep: item.nextStep ? item.nextStep : "-",
//                         creatorRole: item.creatorRole ? item.creatorRole : "-",
//                         noteTemplateId: item.noteTemplateId ? item.noteTemplateId : null,
//                         createdBy: item.account
//                             ? `${item.account.firstName} ${item.account.lastName}`
//                             : "-",
//                     }));
//                     resolve(notes);
//                 } else {
//                     console.log("No notes data found");
//                     resolve([]);
//                 }
//             })
//     })
// };


// export let getClientContactRelDetail = (id) => {
//   return new Promise((resolve, rejects) => {
//     fetch(CLIENT_CONTACTREL_LIST + id)
//       .then((response) => response.json())
//       .then(
//         (data) => {
//           resolve(data);
//         },
//         (rejects) => {
//           global.AjaxFailToast();
//           resolve(true);
//         }
//       );
//   });
// };

export let saveClientToDo = (data, id) => {
    return global.fetchWrapper.post(TODO_SAVE + id, data).then(
        (response) => {
            // global.log("response", response);
        },
        (error) => {
            global.log("error", error);
        }
    );
};

export let removeClientTodo = (id) => {
    return global.fetchWrapper.post(TODO_REMOVE + id).then(
        (response) => {
            // global.log("response", response);
        },
        (error) => {
            global.log("error", error);
            global.AjaxFailToast();
        }
    );
};

export const todoColumns = [
    { field: "title", headerName: "Title", flex: 1, sortable: false, enableTooltip: true, enableDialog: true, },
    { field: "description", headerName: "Description", flex: 1.7, sortable: false, enableTooltip: true, enableDialog: true, },
    { field: "assignee", headerName: "Assignee", flex: 1.5 },
    { field: "startDate", headerName: "Start", flex: 0.5 },
    { field: "dueDate", headerName: "Due", flex: 0.5 },
    // { field: "approverd_hours", headerName: "Approved Hours", flex: 1 },
    { field: "completedDate", headerName: "Completed", flex: 0.8 },
    { field: "creator", headerName: "Created By", flex: 0.8 },
];

export const getTemplateNotes = (date, templateId, id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_MONTHLY_PROGRESS_LIST + id + '?dateFrom=' + date.dateFrom + '&dateTo=' + date.dateTo + '&templateIds=' + templateId)
            .then((data) => {
                if (data.responseCode === 200) {
                    resolve(data.result);
                } else {
                    resolve(true);
                }
            }, (rejects) => {
                global.log('rejects');
                // rejects();
                resolve(true);
            });
    });
}

export const dismissProgressTodo = (taskId) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper
            .put(DISMISS_PROGRESS_TODO + taskId)
            .then((data) => {
                if (data) {
                    resolve(data);
                    global.AjaxSaved1(`Task ${taskId} dismissed Successfully!`);
                } else {
                    resolve(false);
                }
            },
                (rejects) => {
                    resolve(false);
                }
            );
    })
}

export let getClientProgressDetail = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_PROGRESS_LIST + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(data.result);
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    });
};

export const progressColumns = [
    //{ field: 'provider', headerName: 'Provider', flex: 2, sortable: false },
    { field: "createdBy", headerName: "Created By", flex: 1 },
    { field: "noteDate", headerName: "Note Date", flex: 1, type: 'date' },
    { field: "title", headerName: "Title", flex: 2, sortable: false, enableTooltip: true, enableDialog: true },
    { field: "noteDescription", headerName: "Note", flex: 3, sortable: false, enableTooltip: true, enableDialog: true },
    { field: "nextStep", headerName: "Next Step", flex: 2, sortable: false, enableTooltip: true, enableDialog: true },
    //{ field: "creatorRole", headerName: "Access Level", flex: 0.3 },
];

export let getClientContactDetail = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_CONTACT_LIST + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(data.result);
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    });
};

export const contactColumns = [
    { field: "name", headerName: "Name", flex: 1.5 },
    { field: "phone", headerName: "Phone", flex: 1.5, sortable: false },
    { field: "email", headerName: "Email", flex: 1.5, sortable: false },
    {
        field: "relationship",
        headerName: "Relationship",
        flex: 1,
        sortable: false,
    },
];

export let getClientDetailProgram = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_DETAIL_PROGRAM + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(replaceNull(data.result, ""));
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    });
};


export let getClientDetailProgramLight = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_DETAIL_PROGRAM_Light + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(replaceNull(data.result, ""));
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    });
};

export let saveClientDetailProgramLight = (data) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(CLIENT_DETAIL_PROGRAM_Light_SAVE, data)
            .then(
                (data) => {
                    if (data) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    resolve(false);
                }
            );
    });
};

export let getClientDetailProgramP3 = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_DETAIL_PROGRAM_P3 + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(replaceNull(data.result, ""));
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    });
};

export let saveClientDetailProgramP3 = (data) => {
    return new Promise((resolve, rejects) => {

        global.fetchWrapper.post(CLIENT_DETAIL_PROGRAM_P3_SAVE, data)
            .then(
                (data) => {
                    if (data) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    resolve(false);
                }
            );
    });
};

export let getClientDetailProgramComfortHealth = (id) => {
    return new Promise((resolve, rejects) => {

        global.fetchWrapper.get(CLIENT_DETAIL_PROGRAM_ComfortHealth + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(replaceNull(data.result, ""));
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    });
};

export let saveClientDetailProgramComfortHealth = (data) => {
    //global.log('the data from the save client details for comforthealth program', data)
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(CLIENT_DETAIL_PROGRAM_ComfortHealth_SAVE, data)
            .then(
                (data) => {
                    if (data) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    resolve(false);
                }
            );
    });
};

export let saveClientFromTable = (data) => {
    //global.log(data);
    var url =
        CLIENT_DETAIL_PROGRAM_FROMCLIENT +
        data.id +
        "/" +
        (data.cin === "" ? " " : data.cin) +
        "/" +
        data.comfortHealthCareManagerSupervisor_ContactId +
        "/" +
        data.comfortHealthCareManager_ContactId +
        "/" +
        (data.cans === "" ? "empty" : data.cans);

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(url)
            .then(
                (data) => {
                    if (data) {
                        resolve(true);
                    } else {
                        global.AjaxFailToast();
                        resolve(false);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    });
};

export let saveClientDetailProgram = (id, data) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(CLIENT_DETAIL_PROGRAM + id, data)
            .then(
                (data) => {
                    if (data) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                (rejects) => {
                    resolve(false);
                }
            );
    });
};

export let getClientHistoryDetail = (id) => {
    return new Promise((resolve, rejects) => {

        global.fetchWrapper.get(CLIENT_HISTORY_LIST + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        resolve(data.result);
                    } else {
                        resolve(true);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve(true);
                }
            );
    });
};

export let getClientProgramLists = (id) => {
    return new Promise((resolve, rejects) => {

        global.fetchWrapper.get(CLIENT_EDIT_PROGRAM_LIST + id)
            .then(
                (data) => {
                    //global.log(data);
                    if (data.responseCode == 200) {
                        resolve(data.result);
                    } else {
                        resolve([]);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve([]);
                }
            );
    });
};

export let editDocumentFile = (id, file) => {
    //global.log(id + name);return;
    var url = CLIENT_PROGRAM_DOCUMENT_EDIT;

    const payload = { id: id, fileBytes: file === null ? "" : file };
    //global.log(url);
    //global.log(payload);

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(url, payload).then(
            (response) => {
                //return response;
                resolve(response);
            },
            (error) => {
                //return -1;
                resolve(error);
                global.log("error", error);
                global.AjaxFailToast();
            }
        );
    });
};

export let uploadClientTemplateFile = (clientId, templateId, fileName, fileExtension, file, folderTemplate) => {
    var url = CLIENT_PROGRAM_DOCUMENT_UPLOAD;

    const payload = { id: clientId, fileBytes: file, fileName: fileName, templateId: templateId, fileExtension: fileExtension, folderTemplate: folderTemplate }

    return new Promise((resolve, rejects) => {
        global.fetchWrapper.post(url, payload).then(
            (response) => {
                resolve(response)
            },
            (error) => {
                resolve(error);
                global.AjaxFailToast();
            }
        )
    })
}

export let getClientScheduleList = (id) => {
    return new Promise((resolve, rejects) => {
        global.fetchWrapper.get(CLIENT_SCHEDULE_LIST + id)
            .then(
                (data) => {
                    if (data.responseCode === 200) {
                        let scheduleList = [];
                        let scheduleList_data = data.result;
                        !_.isEmpty(scheduleList_data) &&
                            scheduleList_data.forEach((item, index) => {
                                scheduleList.push({
                                    ...item.schedule,
                                    id: item.id ? item.id : index,
                                    provider: item.employee
                                        ? item.employee.contact.firstName +
                                        " " +
                                        item.employee.contact.lastName
                                        : "",
                                    serviceName: item.service?.serviceName,
                                    startTime: item.schedule.startTime
                                        ? global._DateField(item.schedule.startTime)
                                        : null,
                                    endTime: item.schedule.endTime
                                        ? global._DateField(item.schedule.endTime)
                                        : null,
                                });
                            });
                        // global.log("scheduleList =", scheduleList);
                        resolve(scheduleList);
                    } else {
                        resolve([]);
                    }
                },
                (rejects) => {
                    global.AjaxFailToast();
                    resolve([]);
                }
            );
    });
};
export const auditLogColumns = [
    { field: "id", headerName: "Log ID", flex: 1 },
    { field: "userName", headerName: "User", flex: 2 },
    { field: "auditDate", headerName: "Date Time", flex: 2, type: 'dateTime' },
    { field: "refTable", headerName: "Model", flex: 2 },
    { field: "refId", headerName: "Entity Id", flex: 1 },
    { field: "eventType", headerName: "Action Type", flex: 1 },
];
export const auditLogDetailColumns = [
    { field: "eventType", headerName: "Field Changed", flex: 2 },
    { field: "oldJsonData", headerName: "Original Value", flex: 2 },
    { field: "newJsonData", headerName: "New Value", flex: 2 },
];
export const historyColumns = [
    { field: "name", headerName: "Name", width: 300 },
    { field: "by_issued_name", headerName: "Issued By", width: 200 },
    { field: "issued_time", headerName: "Issued Time", width: 100 },
];
export const documentsColums = [
    // { field: '', headerName: 'Type', flex: 1, sortable: false,  renderCell: () => { return <InsertDriveFileIcon />  } },
    { field: "name", headerName: "Name", flex: 3 },
    { field: "created_on", headerName: "Created On", flex: 2 },
    { field: "last_modified", headerName: "Last Modified", flex: 2 },
    { field: "uploaded_by", headerName: "Uploaded By", flex: 2 },
];

export const scheduleRow = [
    {
        id: 1,
        provider: "Mosher Ber Moskowitz",
        serviceName: "Com Hab",
        T: "08:45 AM",
        end_time: "10:15 AM",
    },
];

export const scheduleColumns = [
    { field: "provider", headerName: "Provider", flex: 1 },
    { field: "serviceName", headerName: "Service Name", flex: 1 },
    { field: "startTime", headerName: "Start Time", flex: 1 },
    { field: "endTime", headerName: "End Time", flex: 1 },
];

