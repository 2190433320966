import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import {
    FormControl,
    FormHelperText,
    Grid,
    Button,
    Input,
    TextField,
    InputLabel,
    MenuItem, Select,
    Card, CardContent, Typography, Divider
} from "@mui/material";
import { SectionComponent, NamesToStringComp, EmployeeTypesToString, EmployeeTypeSelector, CompSelectbox, CompTextField } from "./components";
import baseStyles from '../../../assets/styles';
import _ from "lodash";
import clsx from "clsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import SelectEditor from "../../../components/common/UI/Inputs/Select/SelectEditor";
import useUnsavedChangesWarn from "../../../hooks/useUnsavedChangesWarn";
import { periodicities } from "../../../_helpers/constants/scheduleConstants";
import { users } from "../../../redux/users"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

export default function PersonalSection({ sectionState, positionValues, employee, yungermanId, professionalId, setSectionState, specialtyValues, paymentValues, professionalTypes, onBackUrl, rollbackChanges, confirmUnsavedChanges }) {
    const baseClasses = baseStyles();
    const theme = useTheme();
    const [employeeDetail, setEmployeeDetail] = useState(employee);
    const setSectionChanged1 = useStoreActions((actions) => actions.employee.setSectionChanged1);
    const [selectedNeighborhood, setSelectedNeighborhood] = React.useState("");
    const [selectedHasTextOption, setSelectedHasTextOption] = React.useState("");
    const [levelOfEducation, setLevelOfEducation] = React.useState("");
    const [selectedHasCarAllYearOption, setSelectedHasCarAllYearOption] = React.useState("");
    const [options, setOptions] = useState([]);
    const [selectedRatePeriodOption, setSelectedRatePeriodOption] = React.useState("");
    const [neighborhoodText, setNeighborhoodText] = React.useState("");
    const neighborhoodValues = useStoreState(state => state.list.values.neighborhoods);
    const neighborhoodsIsFetched = useStoreState(state => state.list.values.neighborhoodsIsFetched);
    const fetchNeighborhoods = useStoreActions(actions => actions.list.values.fetchNeighborhoods);
    const [isValidEmployeeType, setIsValidEmployeeType] = useState(false)
    const [showEmployeeTypeError, setShowEmployeeTypeError] = useState(false)
    const [isFirstNameValid, setIsFirstNameValid] = useState(false)
    const [showFirstNameError, setShowFirstNameError] = useState(false)
    const [isLastNameValid, setIsLastNameValid] = useState(false)
    const [showLastNameError, setShowLastNameError] = useState(false)
    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;
    const handleNeighborhoodChange = (event) => {
        setSelectedNeighborhood(event.target.value);
        setEmployeeDetail2({ ...employeeDetail, "contact": { ...employeeDetail.contact, "neighborhood": event.target.value } });
        setDirty();
    }
    const handleHasTextChange = event => {
        setSelectedHasTextOption(event.target.value)
        setEmployeeDetail2({ ...employeeDetail, "contact": { ...employeeDetail.contact, "hasText": event.target.value === "null" ? null : event.target.value } });
        setDirty();
    }
    const handleHasCarAllYearChange = event => {
        setSelectedHasCarAllYearOption(event.target.value)
        setEmployeeDetail2({
            ...employeeDetail,
            hasCarAllYear: event.target.value === "null" ? null : event.target.value
        }); setDirty();
    }

    const handleChangeLOE = event => {
        setEmployeeDetail2({ ...employeeDetail, "LevelOfEducation": event.target.value });
        setLevelOfEducation(event.target.value)
    }
    const handleRatePeriodChange = event => {
        setSelectedRatePeriodOption(event.target.value)
        setEmployeeDetail2({ ...employeeDetail, "ratePeriod": event.target.value });
        setDirty();
    }

    useEffect(() => {
        setLevelOfEducation(employeeDetail.levelOfEducation)
    }, [employeeDetail.levelOfEducation])

    useEffect(() => {
        if (!neighborhoodsIsFetched) {
            fetchNeighborhoods();
        }
    }
        , [])
    useEffect(() => {
        setEmployeeDetail(employee);
        setSelectedNeighborhood(employee.contact.neighborhood);
        setSelectedHasTextOption(employee.contact.hasText === null ? "null" : employee.contact.hasText);
        setSelectedHasCarAllYearOption(employee.hasCarAllYear === null ? "null" : employee.hasCarAllYear);
        setSelectedRatePeriodOption(employee.ratePeriod);
        setClean();
    }, [employee]);
    useEffect(() => {
        setClean();
    }, []);

    let sectionState2 = sectionState;
    const exploringId = useStoreState((state) => state.exploringId);

    const setEmployeeDetail2 = (detail) => {
        setEmployeeDetail(detail);
        setDirty();
    }

    const validateBeforeSave = () => {
        const readyToSave = isValidEmployeeType && isFirstNameValid && isLastNameValid
        setShowEmployeeTypeError(!isValidEmployeeType);
        setShowFirstNameError(!isFirstNameValid);
        setShowLastNameError(!isLastNameValid)
        return readyToSave;
    }

    const CompTextFieldContact = ({ xs, label, name, showError, setValid }) => {
        const [value, setValue] = React.useState(employeeDetail.contact[name]);

        useEffect(() => {
            setValue(employeeDetail.contact[name]);
        }, [employeeDetail]);
        useEffect(() => {
            if (typeof setValid === 'function') {
                setValid(value !== "")
            }
        })
        const handleAge = (value) => {
            return isNaN(parseInt(value)) ? 0 : parseInt(value)
        }
        return (
            <Grid item xs={xs}>
                <div>
                    <FormControl fullWidth>
                        <InputLabel >{label}</InputLabel>
                        <Input
                            type={name === "age" ? "number" : "text"}
                            value={global.isEmpty(value) ? "" : value}
                            // value={employeeDetail.contact[name]}
                            onChange={(event) => {
                                setValue(event.target.value)
                                setDirty();
                            }}
                            onBlur={(event) => {
                                global.log(event.target.value);
                                setEmployeeDetail2({
                                    ...employeeDetail,
                                    ["contact"]: {
                                        ...employeeDetail.contact,
                                        [name]: name === "age" ? handleAge(event.target.value) : event.target.value
                                    }
                                })
                                setDirty();
                            }}
                            aria-describedby={"my-helper-text" + name}
                        />
                        {showError && (
                            <FormHelperText
                                className={baseClasses.error}
                                id={"my-helper-text" + name}
                            >
                                required field
                            </FormHelperText>
                        )}
                    </FormControl>
                </div>
            </Grid>
        );
    };

    return (
        <SectionComponent
            headerTitle="Personal"
            onSave={() => { }}
            onBeforeSave={validateBeforeSave}
            onBackUrl={onBackUrl}
            employeeDetail={employeeDetail}
            state={sectionState2}
            onStateChange={setSectionState}
            rollbackChanges={rollbackChanges}
            confirmUnsavedChanges={confirmUnsavedChanges}
            readDOM={
                <Grid
                    container
                    className={baseClasses.grid}
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Employee Type:  </div>
                        <EmployeeTypesToString employeeDetail={employeeDetail} />
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Name: </div>
                        <NamesToStringComp nameList={["firstName", "lastName"]} employeeDetail={employeeDetail} />
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Hebrew Name:</div>
                        <NamesToStringComp nameList={["firstNameHebrew", "lastNameHebrew"]} employeeDetail={employeeDetail} />
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Legal Name:</div>
                        <NamesToStringComp nameList={["firstNameLegal", "middleNameLegal", "lastNameLegal"]} employeeDetail={employeeDetail} />
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Address:</div>
                        <NamesToStringComp nameList={["address1", "address2", "city", "state", "zip"]} employeeDetail={employeeDetail} />
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Neighborhood: </div>
                        <NamesToStringComp nameList={["neighborhood"]} employeeDetail={employeeDetail} />
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}> Cell Number: </div>
                        <NamesToStringComp nameList={["phone1"]} employeeDetail={employeeDetail} />
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}> Has Text? </div>
                        <NamesToStringComp nameList={["hasText"]} employeeDetail={employeeDetail} />
                    </Grid>
                    {/* for some reason the NamesToStringComp doesn't work with this field. i think its because we are not sending in a contact*/}
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Has Car All Year?</div>
                        <div className={baseClasses.fieldvalue}>
                            {employeeDetail.hasCarAllYear === true
                                ? "Yes"
                                : employeeDetail.hasCarAllYear === false
                                    ? "No"
                                    : "-"}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}> Home Number: </div>
                        <NamesToStringComp nameList={["phone2"]} employeeDetail={employeeDetail} />
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}> Email: </div>
                        <NamesToStringComp nameList={["email1"]} employeeDetail={employeeDetail} />
                    </Grid>

                    {/* {exploringId === -1 &&
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <div className={baseClasses.fieldtitle}>Employee type: </div>
                            <div className={baseClasses.fieldvalue}>
                                {
                                    global.isEmpty(positionValues) || global.isEmpty(employeeDetail.positionId)
                                        ? "-"
                                        : !_.isEmpty(positionValues) &&
                                        positionValues.map((column) =>
                                            column.id === employeeDetail.positionId ? column.name : ""
                                        )
                                }
                            </div>
                        </Grid>
                    } */}

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Age: </div>
                        <NamesToStringComp nameList={["age"]} employeeDetail={employeeDetail} />
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Year Experience with Bucherim: </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail['yearsOfExperienceWithBucherim']) ? "-" : employeeDetail['yearsOfExperienceWithBucherim']}
                        </div>
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Level Of Education: </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail['levelOfEducation']) ? "-" : employeeDetail['levelOfEducation']}
                        </div>
                    </Grid>
                    {/* <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Rosh Maamid: </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail['roshMaamidNew'])
                                ? '' // If roshMaamid is empty, show an empty string
                                : `${employeeDetail['roshMaamidNew'].firstName} ${employeeDetail['roshMaamidNew'].lastName}`}
                        </div>
                    </Grid> */}
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Rate : </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail['rate']) ? "-" : `$${employeeDetail['rate']} ${periodicities[employeeDetail['ratePeriod']] ?? ""}`}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Rate Note : </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail['rateNote']) ? "-" : employeeDetail['rateNote']}
                        </div>
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Screened By : </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail['screenedByName']) ? "-" : employeeDetail['screenedByName']}
                        </div>
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Screened On : </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail['screenedOn']) ? "-" : global._DateField(employeeDetail['screenedOn'])}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Screening Notes : </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail['screeningNotes']) ? "-" : employeeDetail['screeningNotes']}
                        </div>
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Interviewed By : </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail['interviewedByName']) ? "-" : employeeDetail['interviewedByName']}
                        </div>
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Interviewed On : </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail['interviewedOn']) ? "-" : global._DateField(employeeDetail['interviewedOn'])}
                        </div>
                    </Grid>
                    <Grid item xs={12} className={baseClasses.fieldLine}>
                        <div className={baseClasses.fieldtitle}>Interview Notes : </div>
                        <div className={baseClasses.fieldvalue}>
                            {global.isEmpty(employeeDetail['interviewNotes']) ? "-" : employeeDetail['interviewNotes']}
                        </div>
                    </Grid>
                </Grid>
            }
            editDOM={
                <Grid
                    container
                    className={clsx(baseClasses.grid)}
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid
                        xs={12} style={{ background: "#ecf0f57a", padding: 10, paddingBottom: 0 }}
                    >
                        <EmployeeTypeSelector
                            employeeDetail={employeeDetail}
                            setEmployeeDetail={setEmployeeDetail}
                            setIsValidEmployeeType={setIsValidEmployeeType}
                            showError={showEmployeeTypeError}
                        />
                    </Grid>
                    {CompTextFieldContact({ xs: 6, label: "First Name", name: "firstName", showError: showFirstNameError, setValid: setIsFirstNameValid })}
                    {CompTextFieldContact({ xs: 6, label: "Last Name", name: "lastName", showError: showLastNameError, setValid: setIsLastNameValid })}
                    {CompTextFieldContact({ xs: 6, label: "Hebrew First Name", name: "firstNameHebrew" })}
                    {CompTextFieldContact({ xs: 6, label: "Hebrew Last Name", name: "lastNameHebrew" })}
                    {CompTextFieldContact({ xs: 4, label: "Legal First Name", name: "firstNameLegal" })}
                    {CompTextFieldContact({ xs: 4, label: "Legal Middle Name", name: "middleNameLegal" })}
                    {CompTextFieldContact({ xs: 4, label: "Legal Last Name", name: "lastNameLegal" })}
                    {CompTextFieldContact({ xs: 4, label: "Address", name: "address1" })}
                    {CompTextFieldContact({ xs: 4, label: "Apt", name: "address2" })}
                    <Grid item xs={4}>
                        <FormControl fullWidth className={baseClasses.optionsContract}>
                            <InputLabel htmlFor="neighborhood-select" id="neighborhood-label">Neighborhood</InputLabel>
                            <Select
                                labelId="neighborhood-label"
                                id="neighborhood-select"
                                value={selectedNeighborhood}
                                className={baseClasses.selection}
                                onChange={handleNeighborhoodChange}
                            >
                                {neighborhoodValues.map((neighborhood, index) =>
                                    <MenuItem key={index} value={neighborhoodValues[index]}>{neighborhood}</MenuItem>
                                )}
                            </Select>
                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                        </FormControl>
                    </Grid>
                    {CompTextFieldContact({ xs: 4, label: "City", name: "city" })}
                    {CompTextFieldContact({ xs: 4, label: "State", name: "state" })}
                    {CompTextFieldContact({ xs: 4, label: "Zip", name: "zip" })}
                    {CompTextFieldContact({ xs: 3, label: "Cell Number", name: "phone1" })}
                    <Grid item xs={1}>
                        <FormControl fullWidth className={baseClasses.optionsContract}>
                            <InputLabel htmlFor="hasText-select" id="neighborhood-label">Has Text?</InputLabel>
                            <Select
                                labelId="hasText-label"
                                id="hasText-select"
                                value={selectedHasTextOption}
                                className={baseClasses.selection}
                                onChange={handleHasTextChange}
                            >

                                <MenuItem key={1} value={"null"}>N/A</MenuItem>
                                <MenuItem key={2} value={true}>Yes</MenuItem>
                                <MenuItem key={3} value={false}>No</MenuItem>

                            </Select>
                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <FormControl fullWidth className={baseClasses.optionsContract}>
                            <InputLabel htmlFor="hasCarAllYear-select" id="neighborhood-label">Has Car All Year?</InputLabel>
                            <Select
                                labelId="hasCarAllYear-label"
                                id="hasCarAllYear-select"
                                value={selectedHasCarAllYearOption}
                                className={baseClasses.selection}
                                onChange={handleHasCarAllYearChange}
                            >

                                <MenuItem key={1} value={"null"}>N/A</MenuItem>
                                <MenuItem key={2} value={true}>Yes</MenuItem>
                                <MenuItem key={3} value={false}>No</MenuItem>

                            </Select>
                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                        </FormControl>
                    </Grid>
                    {CompTextFieldContact({ xs: 4, label: "Home Number", name: "phone2" })}
                    {CompTextFieldContact({ xs: 4, label: "Email", name: "email1" })}
                    {/* {exploringId === -1 &&
                        <Grid item xs={12} className={baseClasses.fieldLine}>
                            <CompSelectbox label="Employee type" name={"positionId"} value={employeeDetail.positionId}
                                employeeDetail={employeeDetail}
                                setEmployeeDetail={setEmployeeDetail2}
                                positionValues={positionValues}
                                specialtyValues={specialtyValues}
                                paymentValues={paymentValues}
                                professionalTypes={professionalTypes}
                            >
                            </CompSelectbox>
                        </Grid>
                    } */}
                    <CompTextFieldContact xs={4} label="Age" name="age" employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail2} />
                    {/* <CompTextField xs={8} label="Level Of Education" name="LevelOfEducation" employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail2} /> */}
                    <Grid item xs={4}>
                        <FormControl fullWidth className={baseClasses.optionsContract}>
                            <InputLabel>Level of Education</InputLabel>
                            <Select
                                name="LevelOfEducation"
                                value={levelOfEducation}
                                onChange={handleChangeLOE}
                            >
                                <MenuItem value="High school diploma">High school diploma</MenuItem>
                                <MenuItem value="BA">BA</MenuItem>
                                <MenuItem value="P3">P3</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* <CompTextField xs={8} label="Level Of Education" name="levelOfEducation" employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail2} /> */}
                    <CompTextField xs={4} label="rate" name="rate" employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail2} />
                    <Grid item xs={4}>
                        <FormControl fullWidth className={baseClasses.optionsContract}>
                            <InputLabel htmlFor="ratePerdiod-select" id="ratePeriod-label">Rate Period</InputLabel>
                            <Select
                                labelId="ratePeriod-label"
                                id="ratePerdiod-select"
                                value={selectedRatePeriodOption}
                                className={baseClasses.selection}
                                onChange={handleRatePeriodChange}
                                options={options}
                            >
                                <MenuItem key={1} value={"HOURS"}>{periodicities.HOURS}</MenuItem>
                                <MenuItem key={2} value={"MONTHS"}>{periodicities.MONTHS}</MenuItem>
                                <MenuItem key={3} value={"SESSION"}>{periodicities.SESSION}</MenuItem>
                            </Select>
                            {<FormHelperText id="my-helper-text"></FormHelperText>}
                        </FormControl>
                    </Grid>
                    <CompTextField xs={4} label="Rate Note" name="rateNote" employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail2} />
                    <CompTextField xs={4} label="Screened By" name="screenedByName" employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail2} />
                    <CompTextField xs={4} label="Screening Notes" name="screeningNotes" employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail2} />
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker xs={4}
                                label="Screened On"
                                format="MM/dd/yyyy"
                                size="small"
                                variant="standard"

                                fullWidth
                                value={employeeDetail["screenedOn"]}
                                onChange={(newValue) => {
                                    if (!newValue || newValue === "Invalid Date") return;
                                    setDirty();
                                    setEmployeeDetail({ ...employeeDetail, screenedOn: newValue });
                                }}
                                minDate={new Date(2000, 1, 1)}
                                maxDate={new Date(2100, 1, 1)}
                                renderInput={(params) => (
                                    <TextField {...params} size="small" variant="standard" fullWidth

                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {params.InputProps.endAdornment}
                                                    {employeeDetail["screenedOn"] && (
                                                        <IconButton
                                                            onClick={() => {
                                                                setDirty();
                                                                setEmployeeDetail({ ...employeeDetail, screenedOn: null });
                                                            }}
                                                            size="small"
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    )}
                                                </>
                                            ),
                                        }}


                                    />
                                )}

                            />
                        </LocalizationProvider>
                    </Grid>
                    {/* <CompTextField xs={4} label="Screened On" name="screenedOn" employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail2} /> */}

                    <CompTextField xs={4} label="Interviewed By" name="interviewedByName" employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail2} />
                    <CompTextField xs={4} label="Interview Notes" name="interviewNotes" employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail2} />
                    {/* <CompTextField xs={4} label="Interviewed On" name="interviewedOn" employeeDetail={employeeDetail} setEmployeeDetail={setEmployeeDetail2} /> */}
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                xs={4}
                                label="Interviewed On"
                                format="MM/dd/yyyy"
                                size="small"
                                variant="standard"
                                fullWidth
                                value={employeeDetail["interviewedOn"] || null} // Allow null value
                                onChange={(newValue) => {
                                    if (newValue === null || newValue === "Invalid Date") {
                                        setDirty();
                                        setEmployeeDetail({ ...employeeDetail, interviewedOn: null });
                                        return;
                                    }
                                    setDirty();
                                    setEmployeeDetail({ ...employeeDetail, interviewedOn: newValue });
                                }}
                                minDate={new Date(2000, 1, 1)}
                                maxDate={new Date(2100, 1, 1)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="standard"
                                        fullWidth
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {params.InputProps.endAdornment}
                                                    {employeeDetail["interviewedOn"] && (
                                                        <IconButton
                                                            onClick={() => {
                                                                setDirty();
                                                                setEmployeeDetail({ ...employeeDetail, interviewedOn: null });
                                                            }}
                                                            size="small"
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    )}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />

                        </LocalizationProvider>
                    </Grid>

                </Grid>
            }
        ></SectionComponent>
    )
};
