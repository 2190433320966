import { strings } from "../_helpers/strings";

export const APP_VERSION = '1.0.0';
export const BASE_URL_LIVE = 'https://lhisalos-new.azurewebsites.net/';// 'https://leargen-app.azurewebsites.net/';// 'https://leargen.org/';
export const BASE_URL_STAGING = 'https://lhisalos-staging.azurewebsites.net/';
export const BASE_URL_NODE = 'https://localhost:5001/';
export const BASE_URL_IIS = "https://localhost:44375/";
export const BASE_URL = BASE_URL_LIVE;

export const LOGIN_ROUTER = "/login";
export const CLIENT_DETAILS_ROUTER = "/client/:id/details";
export const EMPLOYEE_DETAILS_ROUTER = "/employee/:id/details";
export const PROFESSIONAL_DETAILS_ROUTER = "/professional/:id/details";
export const YESHIVAS_DETAILS_ROUTER = "/yeshivas/:id/details";
export const EDIT_CLIENT_DETAILS_ROUTER = "/client/:clientId/enrollment";
export const EDIT_EMPLOYEE_DETAILS_ROUTER = "/employee/:employeeId/enrollment";
export const EDIT_EMPLOYEE_APPLICANT_DETAILS = "/employee/:applicantId/employee_applicant_enrollment";
export const EDIT_YESHIVAS_DETAILS_ROUTER = "/yeshivas/:yeshivasId/manage";
export const PROGRAM_DETAILS_ROUTER = "/program/:id/details";
export const ALLOWED_CHARACTER_PASSWORD = "!#$%&()*+-_,./:;<>=?@[]~`^{}"
export const CONTACT_ROUTER = "/contacts";
export const SEARCH_ROUTER = "/search";
export const PASSWORD_REGEX = /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!#$%&()*+_,./:;<>=?@~`^{}]).{8,}$/;
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_VALIDATION_MSG = "Please enter valid email";
export const DUMMY_EMAIL = "admin@gmail.com";
export const EMAIL_ERROR_MSG = "User not exist";
export const PASSWORD_VALIDATION_MSG = "Password must have at least 8 characters, 1 letter & 1 number & 1 Special Character";
export const DUMMY_PASSWORD = "Admin@123";
export const PASSWORD_ERROR_MSG = "Please enter correct password";
export const ENTER = "Enter";
export const TAB = "Tab";

// Dashboard
export const DASHBOARD_ACTIVE_CLIENT_SERVICE = BASE_URL + "api/Dashboard/clientActiveServicesList?type=";
export const DASHBOARD_PAYMENT_TYPE_SCHEDULE = BASE_URL + "api/Dashboard/scheduleByPaymentTypeList?";
export const DASHBOARD_CLIENT_SCHEDULE_STATUS = BASE_URL + "api/Dashboard/clientScheduleStatusList";
export const DASHBOARD_ACTIVE_SCHEDULES = BASE_URL + "api/Dashboard/schedule/listAllActive";
export const DASHBOARD_CARDS = BASE_URL + "api/Dashboard/cards";
export const STATUS_STEP_DURATION = BASE_URL + "api/Dashboard/scheduleStatusStepDuration";
export const DASHBOARD_STATUS_STEP_DURATION_AVG = BASE_URL + 'api/dashboardcards/scheduleStatusAverage'

// Data Grid Settings
export const ADD_DATA_GRID_VIEW = BASE_URL + 'api/DataGridViewSettings/create';
export const GET_DATA_GRID_VIEW_LIST = BASE_URL + 'api/DataGridViewSettings/read/';
export const UPDATE_DATA_GRID_VIEW = BASE_URL + 'api/DataGridViewSettings/update';
export const DELETE_DATA_GRID_VIEW = BASE_URL + 'api/DataGridViewSettings/delete/';

// API URL's
export const DUMMY_API = "https://jsonplaceholder.typicode.com/users";
export const PROGRAM_LIST = BASE_URL + "api/Service/Programs/List";
export const PROGRAM_SAVE = BASE_URL + "api/Service/Programs/Save";
export const PROGRAM_GET_ID = BASE_URL + "api/Service/Programs/:id";
export const CLIENT_LIST = BASE_URL + "api/Client/all/withfilters";
export const CLIENT_NAMES = BASE_URL + "api/Client/names/list";
export const CLIENT_DETAILS = BASE_URL + "api/Client/Details/";
export const CLIENT_PROGRAM_LIST = BASE_URL + "api/Client/Programs/List/";
export const CLIENT_NOTES_LIST = BASE_URL + "api/cms/notes/list/Client_Clients/";
export const CLIENT_GENERAL_NOTES = BASE_URL + "api/cms/notes/General/";
export const CLIENT_NOTE_REMOVE = BASE_URL + "api/cms/Notes/Remove/";

export const HTTP_EXCEPTION = BASE_URL + 'api/System/logHttpException'
export const DISMISS_PROGRESS_TODO = BASE_URL + 'api/cms/todo/dismiss/'
export const USER_TODO_ASSIGNED = BASE_URL + "api/cms/AssignedTodo/";
export const USER_TODO_FILTER_BY = { ASSIGNEE: "assignee", CREATOR: "creator" };

export const CLIENT_PROGRAM_DETAIL = BASE_URL + "api/Client/ProgramDetails/";
export const CLIENT_SCHEDULE_LIST = BASE_URL + "api/Client/Schedule/";

// client schedules list
export const CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE = BASE_URL + "api/client/schedule/yungerman/getyungermenscheduleslist/";
export const CLIENT_SCHEDULE_GET_LIST_BY_USER = BASE_URL + "api/Dashboard/schedule/list/";
export const CLIENT_SCHEDULE_GET_PROFESSIONAL_SCHEDULE = BASE_URL + "api/client/schedule/professional/GetProfessionalSchedulesList/";
export const CLIENT_SCHEDULE_GET_APPOINTMENT_SCHEDULE = BASE_URL + "api/client/schedule/appointment/getAppointmentslist/";
export const CLIENT_SCHEDULE_GET_SEEKING_SCHEDULE = BASE_URL + "api/client/schedule/seeking/getseekinglist/";
export const CLIENT_SCHEDULE_GET_HISTORY_SCHEDULE = BASE_URL + "api/client/schedule/History/";
// client schedule details
export const CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE_DETAILS = BASE_URL + "api/client/schedule/yungerman/getyungermanclientschedule/";
export const CLIENT_SCHEDULE_GET_PROFESSIONAL_SCHEDULE_DETAILS = BASE_URL + "api/client/schedule/professional/GetProfessionalClientSchedule/";
// client schedule save
export const CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE_SAVE = BASE_URL + "api/client/schedule/yungerman/Save/";
export const CLIENT_SCHEDULE_GET_PROFESSIONAL_SCHEDULE_SAVE = BASE_URL + "api/client/schedule/professional/Save/";
export const CLIENT_SCHEDULE_GET_APPOINTMENT_SCHEDULE_SAVE = BASE_URL + "api/client/schedule/appointment/Save/";
export const CLIENT_SCHEDULE_GET_APPOINTMENT = BASE_URL + "api/client/schedule/appointment/getClientAppointment/"; // GET only
// client schedule set end-date
export const CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE_END = BASE_URL + "api/client/schedule/yungerman/EndSchedule/";
export const CLIENT_SCHEDULE_GET_PROFESSIONAL_SCHEDULE_END = BASE_URL + "api/client/schedule/professional/EndSchedule/";
export const CLIENT_SCHEDULE_GET_APPOINTMENT_SCHEDULE_END = BASE_URL + "api/client/schedule/appointment/EndSchedule/";
// client schedule set end-date
export const CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE_DELETE = BASE_URL + "api/client/schedule/yungerman/delete/";
export const CLIENT_SCHEDULE_GET_YUNGERMAN_AVAILABILITY_SCHEDULE_DELETE = BASE_URL + "api/cms/schedule/availability/delete/";
export const CLIENT_SCHEDULE_GET_PROFESSIONAL_SCHEDULE_DELETE = BASE_URL + "api/client/schedule/professional/delete/";
export const CLIENT_SCHEDULE_GET_APPOINTMENT_SCHEDULE_DELETE = BASE_URL + "api/client/schedule/appointment/delete/";
// client schedule filter available providers
export const CLIENT_SCHEDULE_GET_YUNGERMAN_SCHEDULE_FILTER = BASE_URL + "api/HR/Employees/Schedule/GetAvailableYungermen/";
export const CLIENT_SCHEDULE_GET_PROFESSIONAL_SCHEDULE_FILTER = BASE_URL + "api/HR/Professional/GetProfessionalsByFilters/";
// client schedules list by provider
export const CLIENT_SCHEDULE_GET_BY_PROVIDER = BASE_URL + "api/schedule/getclientscheduleslist/";
export const GET_CLIENT_INFO = BASE_URL + "api/Client/Info/"
export const CLIENT_CONTACTREL_LIST = BASE_URL + "api/ContactRelationships/clients/";
export const CONTACTREL_LIST = BASE_URL + "api/ContactRelationships/";
export const CONTACTREL_REMOVE = BASE_URL + "api/ContactRelationships/RemoveRelationship/";
export const GET_TODO_LIST = BASE_URL + "api/cms/Todo/list/";
export const TODO_SAVE = BASE_URL + "api/cms/Todo/Save/";
export const TODO_REMOVE = BASE_URL + "api/cms/Todo/Remove/";
export const CMS_TODO_MARK_TASK_COMPLETED = BASE_URL + "api/CMS/MarkTaskCompleted/";

export const CMS_SCHEDULES_SAVE = BASE_URL + "api/CMS/Schedules/Save/";
export const CMS_SCHEDULES_DETAILS = BASE_URL + "api/CMS/Schedules/";

export const MEARGENIM_WITH_MONTHLY_LIST = BASE_URL + "api/Client/Tasks/Monthly/Meargenim"
export const MEARGEN_NOTES = BASE_URL + "api/Client/Tasks/Monthly/MeargenTasks?"
export const CLIENT_MONTHLY_PROGRESS_LIST = BASE_URL + "api/CMS/Notes/TemplateNotes/List/Client_Clients/";
export const CLIENT_PROGRESS_LIST = BASE_URL + "api/Client/ProgressNotes/List/";
export const CLIENT_CONTACT_LIST = BASE_URL + "api/contact/";
export const CLIENT_GET_CONTACT = BASE_URL + "api/Contact/Contacts/Detail/";
export const CMS_CONTACT_BY_USER_ROLE = BASE_URL + "api/CMS/Contacts/";
export const CLIENT_OUTSTANDING_NOTES = BASE_URL + "api/Client/Tasks/Monthly";
export const GET_CLIENT_MISSION_LIST = BASE_URL + "api/client/mission/list/";
export const GET_CLIENT_MISSION_DETAIL = BASE_URL + "api/client/mission/";
export const SAVE_CLIENT_MISSION = BASE_URL + "api/client/mission/save/";
export const DELETE_CLIENT_MISSION = BASE_URL + "api/client/mission/delete/";
export const GET_CLIENT_MISSION_SERVICE_LIST = BASE_URL + "api/client/mission/clientServices/list/";
export const SAVE_CLIENT_MISSION_SERVICE = BASE_URL + "api/client/mission/connectService/";
export const SAVE_CLIENT_MISSION_OBJECTIVE = BASE_URL + "api/client/mission/objectives/save/";
export const SAVE_CLIENT_MISSION_OBJECTIVE_COMPLETE = BASE_URL + "api/client/mission/objective/markCompleted/";
export const SAVE_CLIENT_MISSION_COMPLETE = BASE_URL + "api/client/mission/markCompleted/";
export const REMOVE_CLIENT_MISSION_SERVICE = BASE_URL + "api/client/mission/removeService/";

export const CLIENT_MISSION_SERVICE_MONTHLY_OBJECTIVES = BASE_URL + "api/client/mission/months/";
export const CLIENT_MISSION_ALL_MONTHLY_OBJECTIVES = BASE_URL + "api/client/mission/objectives/";
export const CLIENT_SERVICE_MONTH_DETAIL = BASE_URL + "api/client/mission/MonthLinkage/";
export const SAVE_CLIENT_MISSION_MONTHLY_OBJECTIVES = BASE_URL + "api/client/mission/MonthLinkage?includeUpcoming="

export const CLIENT_HISTORY_LIST = BASE_URL + "api/history/";
export const CLIENT_DETAIL_PROGRAM = BASE_URL + "api/Client/Detail/ProgramList/";
export const CLIENT_DETAIL_PROGRAM_P3 = BASE_URL + "api/Client/Detail/ProgramP3/";
export const CLIENT_DETAIL_PROGRAM_P3_SAVE = BASE_URL + "api/Client/Detail/ProgramP3";
export const CLIENT_DETAIL_PROGRAM_Light = BASE_URL + "api/Client/Detail/ProgramLight/";
export const CLIENT_DETAIL_PROGRAM_Light_SAVE = BASE_URL + "api/Client/Detail/ProgramLight";
export const CLIENT_DETAIL_PROGRAM_ComfortHealth = BASE_URL + "api/Client/Detail/ProgramComfortHealth/";
export const CLIENT_DETAIL_PROGRAM_ComfortHealth_SAVE = BASE_URL + "api/Client/Detail/ProgramComfortHealth";
export const CLIENT_DETAIL_PROGRAM_FROMCLIENT = BASE_URL + "api/Client/Detail/ProgramFromClient/";
export const CLIENT_EDIT_PROGRAM_LIST = BASE_URL + "api/CMS/Document/GetProgramFiles/";
export const GET_FILE_TEMPLATES = BASE_URL + "api/CMS/Document/GetTemplateFiles/";
export const UPLOAD_FILE = BASE_URL + "api/CMS/Document/UploadFile/";
export const GET_EMPLOYEE_TEMPLATE_FILES = BASE_URL + "api/CMS/Document/GetEmployeeTemplateFiles/";
export const CLIENT_PROGRAM_DOCUMENT_EDIT = BASE_URL + "api/CMS/Document/SaveProgramDocument";
export const CLIENT_PROGRAM_DOCUMENT_UPLOAD = BASE_URL + "api/CMS/Documents/UploadClientTemplate";
export const EMPLOYEE_PROGRAM_DOCUMENT_UPLOAD = BASE_URL + "api/CMS/Documents/UploadEmployeeTemplate";

export const SAVE_CLIENT_PROGRAM = BASE_URL + "api/Service/Programs/Save/";
export const SAVE_IMPORTFILE = BASE_URL + "api/Client/ImportData/Save/";
export const SAVE_FILE = BASE_URL + "api/Client/UploadFiles/Upload/";

export const SERVICE_LIST = BASE_URL + "api/Service/ServiceProgram/List/";
export const SAVE_TODO_PROGRAM = BASE_URL + "api/Service/Todos/Save/";
export const SAVE_SCHEDULE_PROGRAM = BASE_URL + "api/Client/Schedule/Save/";
export const CLIENT_PROVIDER_SAVE =
    BASE_URL + "api/Client/schedule/Save_ClientProvider";

export const CLIENT_PROVIDER_EDIT =
    BASE_URL + "api/Client/schedule/Edit_ClientProvider";

export const GET_CLIENT_SCHEDULE_SERVICE =
    BASE_URL + "api/Client/schedule/services/";
export const GET_CLIENT_SCHEDULE_PROFESSIONAL =
    BASE_URL + "api/Client/schedule/professionals/";
export const GET_CLIENT_SCHEDULE_APPOINTMENT =
    BASE_URL + "api/Client/schedule/oneTimeAppointments/";
export const REMOVE_SCHEDULE_PROVIDER =
    BASE_URL + "api/Client/schedule/RemoveProvider/";

export const GET_SCHEDLE_FROM_PROVIDER = BASE_URL + "api/Client/schedule/GET_SCHEDLE_FROM_PROVIDER/";

export const GET_CLIENT_PROGRAM_SERVICE =
    BASE_URL + "api/Service/ClientProgramServices/";

export const EMPLOYEE_AVAILABLE_PROVIDERS_LIST = BASE_URL + "api/HR/Employees/FindAvailableSchedule/";
export const EMPLOYEE_IS_AVAILABLE = BASE_URL + "api/HR/Employees/IsEmployeeAvailable/";

export const LOGIN_URL = BASE_URL + "api/System/Login";
export const SEARCH_TEXT = BASE_URL + "api/Client/Search/";

export const EMPLOYEE_LIST = BASE_URL + "api/HR/Employees/All/withfilters";
export const APPLICATIONS_LIST = BASE_URL + "api/HR/Employees/Applications/";
export const APPLICANT_DETAIL = BASE_URL + "api/HR/GetApplicantInfo/";
export const APPLICANT_SCHEDULES = BASE_URL + "api/HR/GetApplicationsSchedules/";
export const EMPLOYEE_NAMES = BASE_URL + "api/hr/names/list";
export const PROFESSIONAL_NAMES_LIST = BASE_URL + "api/hr/Professionals/List";
export const EMPLOYEE_DETAIL = BASE_URL + "api/HR/GetEmployee/";
export const EMPLOYEE_REMOVE = BASE_URL + "api/HR/RemoveEmployee/";
export const APPLICANT_REMOVE = BASE_URL + "api/Application/DeleteApplication/";
export const EMPLOYEE_DETAIL_PERSONAL = BASE_URL + "api/HR/Employee/Details/";
export const EMPLOYEE_SAVE_PERSONAL = BASE_URL + "api/HR/Employee/Save/Programs/";
export const EmployeeApplicant_SAVE_Enrollment = BASE_URL + "api/HR/EmployeeApplicant/Save/Enrollment/";

export const EMPLOYEE_SAVE_SUMMER = BASE_URL + "api/HR/Employee/Save/Summer/";
export const EMPLOYEE_SAVE_DETAIL = BASE_URL + "api/HR/Employee/Save/Detail/";
export const EMPLOYEE_AVAILABILITY_SCHEDULE = BASE_URL + "api/HR/Employees/Schedule/Availablity/";
export const EMPLOYEE_INFO = BASE_URL + "api/HR/Info/";

export const PROFESSIONAL_LIST = BASE_URL + "api/HR/Professionals";
export const PROFESSIONAL_DETAIL = BASE_URL + "api/HR/GetProfessional/";
export const PROFESSIONAL_REMOVE = BASE_URL + "api/HR/RemoveProfessional/";
export const PROFESSIONAL_DETAIL_PERSONAL = BASE_URL + "api/HR/Professional/Details/";
export const PROFESSIONAL_SAVE_PERSONAL = BASE_URL + "api/HR/Professional/Save/Programs/";
export const PROFESSIONAL_SAVE_SUMMER = BASE_URL + "api/HR/Professional/Save/Summer/";
export const PROFESSIONAL_SAVE_DETAIL = BASE_URL + "api/HR/Professional/Save/Detail/";
export const CreateProfessionalFromApplicant = BASE_URL + "api/HR/CreateProfessionalFromApplicant/Save/Detail/";
export const CreateMamidFromApplicant = BASE_URL + "api/HR/CreateMamidFromApplicant/Save/Detail/";

export const SAVE_CLIENT_DETAILS = BASE_URL + "api/Client/Save/";
export const UPDATE_CLIENT_DETAILS = BASE_URL + "api/Client/Update/";
export const SAVE_CLIENT_RELATIONSHIP = BASE_URL + "api/ContactRelationships/AddExistingContact/";
// export const REMOVE_CLIENT = BASE_URL + "api/Client/ClientDelete/";
export const REMOVE_CLIENT = BASE_URL + "api/Client/RemoveClient/";
export const CONTACT_CMS_LIST = BASE_URL + "api/CMS/Contacts/List";

export const NON_RELATED_CONTACT_CMS_LIST =
    BASE_URL + "api/CMS/Contacts/NonRelated/"; //+ clientId
export const SAVE_CONTACT_CMS_LIST = BASE_URL + "api/CMS/Contacts/Save/";
export const SAVE_CONTACT_ROLE = BASE_URL + "api/CMS/Contacts/SaveRoles/";
export const CMS_NOTES_SAVE = BASE_URL + "api/cms/Notes/Save/";
export const CMS_GENERAL_NOTES_DELETE = BASE_URL + "api/cms/notes/General/delete";

export const CMS_CONTACT_RELATIONSHIP_LIST = BASE_URL + "api/CMS/ContactRelationships/List";

// Yeshivas List
export const YESHIVAS_LIST = BASE_URL + "api/CMS/Yeshivas/List/true";
export const YESHIVAS_CLIENT_LIST = BASE_URL + "api/CMS/Yeshivas/ClientList/";
export const SAVE_YESHIVA = BASE_URL + "api/CMS/Yeshivas/Save/";
export const DELETE_YESHIVA = BASE_URL + "api/CMS/Yeshivas/Delete/";
export const SAVE_YESHIVA_CONTACT = BASE_URL + "api/CMS/Yeshivas_Contact/Save/";
export const YESHIVA_ITEM_DETAILS = BASE_URL + "api/CMS/Yeshivas/";

export const YESHIVA_URL = BASE_URL + 'api/yeshivas'

export const CMS_DOCUMENT_TREESTRUCTURE_GET = BASE_URL + "api/CMS/Document/GetTreeStructure/";
export const CMS_DOCUMENT_FOLDER_SAVE = BASE_URL + "api/CMS/Document/Folder/Save/";
export const CMS_DOCUMENT_FILE_EDIT = BASE_URL + "api/CMS/Document/File/Edit/";
export const CMS_DOCUMENT_FILE_DELETE = BASE_URL + "api/CMS/Document/File/Delete/";
export const CMS_DOCUMENT_FOLDER_DELETE = BASE_URL + "api/CMS/Document/Folder/Delete/";
export const CMS_FILE_UPLOAD = BASE_URL + "api/CMS/UploadFile";

// User List
export const USER_LIST = BASE_URL + "accounts/get-all";
export const USER_SAVE = BASE_URL + "api/User/Save/";
export const USER_DETAIL = BASE_URL + "accounts/"
// User Notifications
export const USER_GET_NOTIFICATIONS = BASE_URL + "api/User/getNotifications/";
export const USER_UPDATE_NOTIFICATION = BASE_URL + "api/User/updateNotification/";
export const USER_NOTIFICATIONS = BASE_URL + "api/User/updateNotification/";

// Global Search
export const GLOBALSEARCH = BASE_URL + "api/Search/"

//all roles
export const GET_ALL_ROLES = BASE_URL + "accounts/roles/get-all";
//permissions
export const GET_PERMISSIONS = BASE_URL + "accounts/get-permissions";
//System
export const SYSTEM_AUDITLOG_LIST = BASE_URL + "api/{table}/AuditLogs/List/";
export const SYSTEM_AUDITLOG_DETAIL = BASE_URL + "api/System/AuditLogs/Detail/";

export const SYSTEM_LISTS_LIST = BASE_URL + "api/System/Lists/List";
export const SYSTEM_LISTS_DETAIL = BASE_URL + "api/System/Lists/";
export const SYSTEM_LISTS_SAVE = BASE_URL + "api/System/Lists/Save/";

export const SYSTEM_LISTVALUES_LIST = BASE_URL + "api/System/ListValues/List/";
export const SYSTEM_LISTVALUES_LIST2 = BASE_URL + "api/System/ListValues/List2/";
export const SYSTEM_LISTVALUES_SAVE = BASE_URL + "api/System/ListValues/Save";

//export const SYSTEM_SETTINGS_LIST = BASE_URL + "api/System/Settings/List/";
//export const SYSTEM_SETTINGS_SAVE = BASE_URL + "api/System/Settings/SaveMulti";
//settings
export const SYSTEM_SETTINGS_SAVE = BASE_URL + "api/System/Settings/Save";
export const GET_SYSTEM_SETTINGS_BY_GROUP = BASE_URL + "api/System/Settings/ByGroup/";

// Timesheets

export const TIMESHEET = BASE_URL + "api/Timesheets/"
export const GET_TIMESHEET_ENTRIES = TIMESHEET + "getTimesheetEntries";
export const SAVE_TIMESHEET_ENTRIES = TIMESHEET + "saveTimesheetEntries";
export const GET_UNAPPROVED_TIMESHEET = BASE_URL + "api/Timesheets/UnapprovedTimesheets?startDate=";
export const APPROVE_TIMESHEET = BASE_URL + "api/Timesheets/approve/";
export const GET_TIMESHET_CLIENT_SERVICE = BASE_URL + "api/Timesheets/GetAllClientServicesTimesheetInfoByHebMonth?hebMonthYear=";
export const GET_TIMESHEET_CLIENT_LIST = BASE_URL + "api/Timesheets/ClientsByMonth?hebMonthYear=";
export const GET_TIMESHEET_CLIENT_SERVICES = BASE_URL + "api/Timesheets/ClientServicesByClientId/";
export const GET_TIMESHEET_SERVICES_MONTH = BASE_URL + "api/Timesheets/TimesheetForServiceMonth/";
export const SAVE_TIMESHEET_CLIENT_SERVICE_MONTH = BASE_URL + "api/Timesheets/TimesheetForServiceMonth";
let drawerStatus = true;

export function setIsDrawerStatus(isDrawerStatus: boolean) {
    const globalConst: any = global;
    globalConst.log("drawerstatus", isDrawerStatus);
    drawerStatus = isDrawerStatus;
}

export function getIsDrawerStatus() {
    return drawerStatus;
}

export const clientFileTemplate = {
    IepForP3: 1,
    InsuranceCard: 2,
    ConsentForm: 3,
    Basc: 4,
    Sed: 5,
    SedReport: 6,
    Lpha: 7,
    ContractWithComfortHealth: 8,
    Plan504: 13,
}

export const employeeFileTemplate = {
    ID: 9,
    ParsonageForm: 10,
    BA: 11,
    HighSchoolDiploma: 12,
    AssociateDegree: 14,
    LhisalosContract: 16,
    I9: 17,
}

export const notificationsFakeData = [
    {
        id: 1,
        type: 'invitation',
        status: 'pending',
        title: 'You got invitation for new class',
        time: 'February 14, 2022',
        reference: 'route'

    },
    {
        id: 2,
        type: 'appointment',
        status: 'completed',
        title: 'Congratulations on Session completion',
        time: 'February 16, 2022',
        reference: 'route'

    },
    {
        id: 3,
        type: 'meeting',
        status: 'late',
        title: 'You got late for today meeting',
        time: 'February 15, 2022',
        reference: 'route'

    },
    {
        id: 4,
        type: 'task',
        status: 'pending',
        title: 'You task is still in pending',
        time: 'February 16, 2022',
        reference: 'route'

    },
    {
        id: 5,
        type: 'task',
        status: 'progress',
        title: 'You task moved to progress',
        time: 'February 17, 2022',
        reference: 'route'

    },
];

export const tabsConst = {
    general: 'general',
    todo: 'todo',
    clients: 'clients',
    clientsWithYungerleit: 'clientsWithYungerleit',
    clientsWithProfessionals: 'clientsWithProfessionals',
    clientScheduleStatus: 'clientScheduleStatus',
    program: 'program',
    schedule: 'schedule',
    documents: 'documents',
    contacts: 'contacts',
    seders: 'seders',
    history: 'history',
    info: 'info',
    progress: 'progress',
    all: 'all',
    monthly: 'monthly',
    timesheet: 'timesheet',
    task: 'task',
    mission: 'mission',
    seekingSchedule: 'seekingSchedule',
    financialSchedule: 'financialSchedule',
    onHoldSchedule: 'onHoldSchedule',
    rejectedSchedule: 'rejectedSchedule',
    activeSchedule: 'activeSchedule',
    paymentTypeSchedule: 'paymentTypeSchedule',
    statusStepDuration: 'statusStepDuration'

}

export const dashboardProgressData = [
    {
        title: 'Met with Bucher',
        noteTemplateId: 1,
        status: 'due',
        clientId: 1,
        clientName: 'abc',
        monthIndex: 0
    },
    {
        title: 'Spoke to the Yeshiva',
        status: 'due',
        noteTemplateId: 2,
        clientId: 2,
        clientName: 'cde',
        monthIndex: 0
    },
    {
        title: 'Sat with the Rosh Meargen',
        status: 'due',
        noteTemplateId: 4,
        clientId: 2,
        clientName: 'cde',
        monthIndex: 0
    },
    {
        title: 'Spoke to the Parents',
        status: 'due',
        noteTemplateId: 3,
        clientId: 3,
        clientName: 'abc',
        monthIndex: 0
    },
    {
        title: 'Spoke to the Yeshiva',
        status: 'due',
        noteTemplateId: 2,
        clientId: 1,
        clientName: 'abc',
        monthIndex: 1
    },
    {
        title: 'Collaborated with the Care Manager',
        status: 'due',
        noteTemplateId: 5,
        clientId: 2,
        clientName: 'abc',
        monthIndex: 0
    },
    {
        title: 'Spoke to the Yeshiva',
        status: 'due',
        noteTemplateId: 2,
        clientId: 3,
        clientName: 'abc',
        monthIndex: 0
    },

];
export const scheduleCardMapping = {
    name: 'name',
    time: 'time',
    schedule: 'schedule',
    rate: 'rate',
    createdon: 'createdon',
    throughout: 'throughout',
    between: 'between',
    nextAppt: 'nextAppt',
    specialties: 'specialties',
    type: 'type',
    note: 'note',
    date: 'date',
    meargen: 'meargen',
    status: 'status',
    editIcon: 'editIcon',
    deleteIcon: 'deleteIcon',
    statusIcon: 'statusIcon',
    expandIcon: 'expandIcon'
}
export const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

export const timesheetDaysTimeFrameConst = {
    Sunday: {
        day: 'Sunday',
        startTime: null,
        endTime: null,
        error: ''
    },
    Monday: {
        day: 'Monday',
        startTime: null,
        endTime: null,
        error: ''
    },
    Tuesday: {
        day: 'Tuesday',
        startTime: null,
        endTime: null,
        error: ''
    },
    Wednesday: {
        day: 'Wednesday',
        startTime: null,
        endTime: null,
        error: ''
    },
    Thursday: {
        day: 'Thursday',
        startTime: null,
        endTime: null,
        error: ''
    },
    Friday: {
        day: 'Friday',
        startTime: null,
        endTime: null,
        error: ''
    },
    Saturday: {
        day: 'Saturday',
        startTime: null,
        endTime: null,
        error: ''
    },
}

export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
export const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const explorerTypesConst = {
    clients: 'clients',
    client: 'client',
    employees: 'employees',
    employee: 'employee',
    yeshivas: 'yeshivas',
    yeshiva: 'yeshiva',
    users: 'users',
    user: 'user',
    professionals: 'professionals',
    professional: 'professional',
    contacts: 'contacts',
    contact: 'contact'
};
export const notificationStatus = {
    pending: 'pending',
    approved: 'approved',
    completed: 'completed',
    confirmed: 'confirmed',
    progress: 'progress',
    late: 'late'
}
export const notificationType = {
    task: 'task',
    appointment: 'appointment',
    meeting: 'meeting',
    invitation: 'invitation',
}

export const CLIENT_COMFORT_HEALTH_STATUS_LIST = [
    'Not Enrolled',
    'Enrolled, Not Approved',
    'On Hold',
    'Approved',
    'Inactive',
    'Pended',
]

export const EMPLOYEE_COMFORT_HEALTH_STATUS_LIST = [
    'Not Enrolled',
    'Pending Application',
    'Application Completed, Not Sent',
    'Pending Fingerprints/Approval',
    'Pending Approval',
    'On Hold',
    'Approved',
]

export const LHISALOS_APPROVAL_STATUS = ["Approved", "Not Approved", "Pending"];

export const templateNotes = [
    {
        noteTemplateId: 1,
        noteDate: '',
        title: 'Met with Bucher',
        noteDescription: '',
        complete: false,
    },
    {
        noteTemplateId: 2,
        noteDate: '',
        title: 'Spoke to the Yeshiva',
        noteDescription: '',
    },
    {
        noteTemplateId: 3,
        noteDate: '',
        title: 'Spoke to the Parents',
        noteDescription: '',
    },
    {
        noteTemplateId: 4,
        noteDate: '',
        title: 'Sat with the Rosh Meargen',
        noteDescription: '',
    },
    {
        noteTemplateId: 5,
        noteDate: '',
        title: 'Collaborated with the Care Manager',
        noteDescription: '',
    }
]

export const MONTHLY_OBJECTIVE_STANDING_OPTIONS = [
    { value: 'Continuation', label: 'Needs to continue' },
    { value: 'Achieved', label: 'Achieved' },
    { value: 'Change', label: 'Needs to be changed' }
]

export const DASHBOARD_CLIENT_WITH_YUNGERLEIT_FILTER_OPTIONS = [
    {
        label: 'With Yungerleit',
        value: 'withYungerleit'
    },
    {
        label: 'Without Yungerleit',
        value: 'withoutYungerleit'
    },
    {
        label: 'All',
        value: 'all'
    },
]

export const DASHBOARD_CLIENT_WITH_PROFESSIONAL_FILTER_OPTIONS = [
    {
        label: 'With Professional',
        value: 'withProfessional'
    },
    {
        label: 'Without Professional',
        value: 'withoutProfessional'
    },
    {
        label: 'All',
        value: 'all'
    },
]

export const TIMESHEET_FILTER_OPTIONS = [
    {
        label: 'Not Uploaded',
        value: 'notUploaded'
    },
    {
        label: 'Uploaded File',
        value: 'uploaded'
    },
    {
        label: 'All',
        value: 'all'
    },
]

export const DASHBOARD_TIMESHEET_FILTER_OPTIONS = [
    {
        label: 'Uploaded File',
        value: 'uploaded'
    },
    {
        label: 'Not Uploaded',
        value: 'notUploaded'
    },
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Approved',
        value: 'approved'
    },
]

export const employeeFilter = {
    mamidim: "mamidim",
    professionals: 'professionals',
    all: 'all',
    applications: 'applications'
}
export const employeeDisplayNames = {
    mamidim: "Mamidim",
    professionals: 'Professionals',
    all: 'Employees',
    applications: 'Applications'

}
export const employeeDataTableScreens = {
    mamidim: "employee_mamidim",
    professionals: 'employee_professionals',
    all: 'employee',
    employees: 'employee', // added this when user hitting url directly /employees
    applications: 'applications'

}
export const employeesScreensArr = Object.values(employeeDataTableScreens)

export const applicantQuickFilters = {
    showApproved: "Show approved"
    // showInactive: "Show Inactive",
    // showPending: "Show Pending",
    // showNotApproved: "Show Non-approved"
}


export const employeeQuickFilters = {
    showInactive: "Show Inactive",
    showPending: "Show Pending",
    showNotApproved: "Show Non-approved"
}
export const clientQuickFilters = {
    showDisenrolled: 'Show Disenrolled'
}

export const employeeChipFilterState = [
    {
        filter: strings.removeEmptySpaces(employeeQuickFilters.showInactive),
        isActive: false
    },
    {
        filter: strings.removeEmptySpaces(employeeQuickFilters.showNotApproved),
        isActive: false
    },
    {
        filter: strings.removeEmptySpaces(employeeQuickFilters.showPending),
        isActive: false
    },
    {
        filter: strings.removeEmptySpaces(applicantQuickFilters.showApproved),
        isActive: false
    },
]

export const clientChipFilterState = [
    {
        filter: strings.removeEmptySpaces(clientQuickFilters.showDisenrolled),
        isActive: false
    }
]

export const hoursInDay = ["12AM", "03AM", "06AM", "09AM", "12PM", "03PM", "06PM", "09PM",];
export const MinsInDay = 1440;


export const defaultColumnsAndFilters = {
    'employee': {
        columnSettings: {
            isYungerman: {
                isVisible: false
            },
            isProfessional: {
                isVisible: false
            },
            isLegal: {
                isVisible: false
            },
            isEmployee: {
                isVisible: false
            },
            inactive: {
                isVisible: false
            },
            id: {
                isVisible: false
            },
            fullName: {
                isVisible: false
            },
            firstNameHebrew: {
                isVisible: false
            },
            lastNameHebrew: {
                isVisible: false
            },
            firstNameLegal: {
                isVisible: false
            },
            lastNameLegal: {
                isVisible: false
            },
            middleNameLegal: {
                isVisible: false
            },
            legalName: {
                isVisible: false
            },
            hasText: {
                isVisible: false
            },
            address1: {
                isVisible: false
            },
            age: {
                isVisible: false
            },
            city: {
                isVisible: false
            },
            email1: {
                isVisible: false
            },
            state: {
                isVisible: false
            },
            zip: {
                isVisible: false
            },
            dob: {
                isVisible: false
            },
            rate: {
                isVisible: false
            },
            prevYeshivas: {
                isVisible: false
            },
            experience: {
                isVisible: false
            },
            note: {
                isVisible: false
            },
            parsonageLimit: {
                isVisible: false
            },
            financialNotes: {
                isVisible: false
            },
            paymentOptions: {
                isVisible: false
            },
            planningToEnrollComfortHealth: {
                isVisible: false
            },
            hasComfortHealthApplication: {
                isVisible: false
            },
            comfortHealthEmployeeId: {
                isVisible: false
            },
            hasBA: {
                isVisible: false
            },
            hasHighSchoolDiploma: {
                isVisible: false
            },
            sentToComfortHealthOn: {
                isVisible: false
            },
            fingerprintedOn: {
                isVisible: false
            },
            comfortHealthApprovedOn: {
                isVisible: false
            },
            catsApprovedOn: {
                isVisible: false
            },
            specialRequirements: {
                isVisible: false
            },
            amountCouldWork: {
                isVisible: false
            },
            isInTheMountainsSummer: {
                isVisible: false
            },
            hasLicense: {
                isVisible: false
            },
            hasCar: {
                isVisible: false
            },
            summerColony: {
                isVisible: false
            },
            amountBilling: {
                isVisible: false
            },
            professionalType: {
                isVisible: false
            },
            isLhisalosInHouse: {
                isVisible: false
            },
            uploadedIdOn: {
                isVisible: false
            },
            uploadedParsonageFormOn: {
                isVisible: false
            },
            uploadedHighSchoolDiplomaOn: {
                isVisible: false
            },
            uploadedBAOn: {
                isVisible: false
            },
            baFileName: {
                isVisible: false
            },
            uploadedI9On: {
                isVisible: false
            },
        },
        filterModel: {
            items: []
        },
    },
    'employee_professionals': {
        columnSettings: {
            isYungerman: {
                isVisible: false
            },
            isProfessional: {
                isVisible: false
            },
            isLegal: {
                isVisible: false
            },
            isEmployee: {
                isVisible: false
            },
            inactive: {
                isVisible: false
            },
            id: {
                isVisible: false
            },
            fullName: {
                isVisible: false
            },
            firstNameHebrew: {
                isVisible: false
            },
            lastNameHebrew: {
                isVisible: false
            },
            firstNameLegal: {
                isVisible: false
            },
            lastNameLegal: {
                isVisible: false
            },
            middleNameLegal: {
                isVisible: false
            },
            legalName: {
                isVisible: false
            },
            hasText: {
                isVisible: false
            },
            address1: {
                isVisible: false
            },
            age: {
                isVisible: false
            },
            city: {
                isVisible: false
            },
            email1: {
                isVisible: false
            },
            state: {
                isVisible: false
            },
            zip: {
                isVisible: false
            },
            dob: {
                isVisible: false
            },
            rate: {
                isVisible: false
            },
            prevYeshivas: {
                isVisible: false
            },
            experience: {
                isVisible: false
            },
            note: {
                isVisible: false
            },
            parsonageLimit: {
                isVisible: false
            },
            financialNotes: {
                isVisible: false
            },
            paymentOptions: {
                isVisible: false
            },
            planningToEnrollComfortHealth: {
                isVisible: false
            },
            hasComfortHealthApplication: {
                isVisible: false
            },
            comfortHealthEmployeeId: {
                isVisible: false
            },
            hasBA: {
                isVisible: false
            },
            hasHighSchoolDiploma: {
                isVisible: false
            },
            sentToComfortHealthOn: {
                isVisible: false
            },
            fingerprintedOn: {
                isVisible: false
            },
            comfortHealthApprovedOn: {
                isVisible: false
            },
            catsApprovedOn: {
                isVisible: false
            },
            specialRequirements: {
                isVisible: false
            },
            amountCouldWork: {
                isVisible: false
            },
            isInTheMountainsSummer: {
                isVisible: false
            },
            hasLicense: {
                isVisible: false
            },
            hasCar: {
                isVisible: false
            },
            summerColony: {
                isVisible: false
            },
            amountBilling: {
                isVisible: false
            },
            professionalType: {
                isVisible: false
            },
            isLhisalosInHouse: {
                isVisible: false
            },
            uploadedIdOn: {
                isVisible: false
            },
            uploadedParsonageFormOn: {
                isVisible: false
            },
            uploadedHighSchoolDiplomaOn: {
                isVisible: false
            },
            uploadedBAOn: {
                isVisible: false
            },
            baFileName: {
                isVisible: false
            },
            uploadedI9On: {
                isVisible: false
            },
        },
        filterModel: {
            items: []
        },
    },
    'employee_mamidim': {
        columnSettings: {
            isYungerman: {
                isVisible: false
            },
            isProfessional: {
                isVisible: false
            },
            isLegal: {
                isVisible: false
            },
            isEmployee: {
                isVisible: false
            },
            inactive: {
                isVisible: false
            },
            id: {
                isVisible: false
            },
            fullName: {
                isVisible: false
            },
            firstNameHebrew: {
                isVisible: false
            },
            lastNameHebrew: {
                isVisible: false
            },
            firstNameLegal: {
                isVisible: false
            },
            lastNameLegal: {
                isVisible: false
            },
            middleNameLegal: {
                isVisible: false
            },
            legalName: {
                isVisible: false
            },
            hasText: {
                isVisible: false
            },
            address1: {
                isVisible: false
            },
            age: {
                isVisible: false
            },
            city: {
                isVisible: false
            },
            email1: {
                isVisible: false
            },
            state: {
                isVisible: false
            },
            zip: {
                isVisible: false
            },
            dob: {
                isVisible: false
            },
            rate: {
                isVisible: false
            },
            prevYeshivas: {
                isVisible: false
            },
            experience: {
                isVisible: false
            },
            note: {
                isVisible: false
            },
            parsonageLimit: {
                isVisible: false
            },
            financialNotes: {
                isVisible: false
            },
            paymentOptions: {
                isVisible: false
            },
            planningToEnrollComfortHealth: {
                isVisible: false
            },
            hasComfortHealthApplication: {
                isVisible: false
            },
            comfortHealthEmployeeId: {
                isVisible: false
            },
            hasBA: {
                isVisible: false
            },
            hasHighSchoolDiploma: {
                isVisible: false
            },
            sentToComfortHealthOn: {
                isVisible: false
            },
            fingerprintedOn: {
                isVisible: false
            },
            comfortHealthApprovedOn: {
                isVisible: false
            },
            catsApprovedOn: {
                isVisible: false
            },
            specialRequirements: {
                isVisible: false
            },
            amountCouldWork: {
                isVisible: false
            },
            isInTheMountainsSummer: {
                isVisible: false
            },
            hasLicense: {
                isVisible: false
            },
            hasCar: {
                isVisible: false
            },
            summerColony: {
                isVisible: false
            },
            amountBilling: {
                isVisible: false
            },
            professionalType: {
                isVisible: false
            },
            isLhisalosInHouse: {
                isVisible: false
            },
            uploadedIdOn: {
                isVisible: false
            },
            uploadedParsonageFormOn: {
                isVisible: false
            },
            uploadedHighSchoolDiplomaOn: {
                isVisible: false
            },
            uploadedBAOn: {
                isVisible: false
            },
            baFileName: {
                isVisible: false
            },
            uploadedI9On: {
                isVisible: false
            },
        },
        filterModel: {
            items: []
        },
    },
    'employee_applications': {
        columnSettings: {
            isYungerman: {
                isVisible: false
            },
            isProfessional: {
                isVisible: false
            },
            isLegal: {
                isVisible: false
            },
            isEmployee: {
                isVisible: false
            },
            inactive: {
                isVisible: false
            },
            id: {
                isVisible: false
            },
            fullName: {
                isVisible: false
            },
            firstNameHebrew: {
                isVisible: false
            },
            lastNameHebrew: {
                isVisible: false
            },
            firstNameLegal: {
                isVisible: false
            },
            lastNameLegal: {
                isVisible: false
            },
            middleNameLegal: {
                isVisible: false
            },
            legalName: {
                isVisible: false
            },
            hasText: {
                isVisible: false
            },
            address1: {
                isVisible: false
            },
            age: {
                isVisible: false
            },
            city: {
                isVisible: false
            },
            email1: {
                isVisible: false
            },
            state: {
                isVisible: false
            },
            zip: {
                isVisible: false
            },
            dob: {
                isVisible: false
            },
            rate: {
                isVisible: false
            },
            prevYeshivas: {
                isVisible: false
            },
            experience: {
                isVisible: false
            },
            note: {
                isVisible: false
            },
            parsonageLimit: {
                isVisible: false
            },
            financialNotes: {
                isVisible: false
            },
            paymentOptions: {
                isVisible: false
            },
            planningToEnrollComfortHealth: {
                isVisible: false
            },
            hasComfortHealthApplication: {
                isVisible: false
            },
            comfortHealthEmployeeId: {
                isVisible: false
            },
            hasBA: {
                isVisible: false
            },
            hasHighSchoolDiploma: {
                isVisible: false
            },
            sentToComfortHealthOn: {
                isVisible: false
            },
            fingerprintedOn: {
                isVisible: false
            },
            comfortHealthApprovedOn: {
                isVisible: false
            },
            catsApprovedOn: {
                isVisible: false
            },
            specialRequirements: {
                isVisible: false
            },
            amountCouldWork: {
                isVisible: false
            },
            isInTheMountainsSummer: {
                isVisible: false
            },
            hasLicense: {
                isVisible: false
            },
            hasCar: {
                isVisible: false
            },
            summerColony: {
                isVisible: false
            },
            amountBilling: {
                isVisible: false
            },
            professionalType: {
                isVisible: false
            },
            isLhisalosInHouse: {
                isVisible: false
            },
            uploadedIdOn: {
                isVisible: false
            },
            uploadedParsonageFormOn: {
                isVisible: false
            },
            uploadedHighSchoolDiplomaOn: {
                isVisible: false
            },
            uploadedBAOn: {
                isVisible: false
            },
            baFileName: {
                isVisible: false
            },
            uploadedI9On: {
                isVisible: false
            },
        },
        filterModel: {
            items: []
        },
    },
    'home': {
        columnSettings: {
            id: {
                isVisible: false
            },
            fullName: {
                isVisible: false
            },
            firstNameHebrew: {
                isVisible: false
            },
            lastNameHebrew: {
                isVisible: false
            },
            legalName: {
                isVisible: false
            },
            roshMeargen: {
                isVisible: false
            },
            careManager: {
                isVisible: false
            },
            careManagerSupervisor: {
                isVisible: false
            },
            hcbsCoordinator: {
                isVisible: false
            },
            neighborhood: {
                isVisible: false
            },
            phone1: {
                isVisible: false
            },
            age: {
                isVisible: false
            },
            dob: {
                isVisible: false
            },
            dateEnrolledComfort: {
                isVisible: false
            },
            dateApprovedComfort: {
                isVisible: false
            },
            isPrevEnrolledInCH: {
                isVisible: false
            },
            comfortNote: {
                isVisible: false
            },
            pendedOn: {
                isVisible: false
            },
            isPended: {
                isVisible: false
            },
            sentDocsToParentsOn: {
                isVisible: false
            },
            insuranceCompany: {
                isVisible: false
            },
            sentBinderToComfortHealthOn: {
                isVisible: false
            },
            careManagerAssignedOn: {
                isVisible: false
            },
            intakeMeetingDate: {
                isVisible: false
            },
            cans_CompletedOn: {
                isVisible: false
            },
            crisis_CompletedOn: {
                isVisible: false
            },
            poc_CompletedOn: {
                isVisible: false
            },
            comprehensiveAssessment_CompletedOn: {
                isVisible: false
            },
            loc_CompletedOn: {
                isVisible: false
            },
            packetSentOn: {
                isVisible: false
            },
            comfortHealthTimeRequirements: {
                isVisible: false
            },
            sentIepToNewAgencyOn: {
                isVisible: false
            },
            dateEnrolledP3: {
                isVisible: false
            },
            dateApprovedP3: {
                isVisible: false
            },
            hoursPerWeekP3: {
                isVisible: false
            },
            dateEnrolledLight: {
                isVisible: false
            },
            enrolledOn: {
                isVisible: false
            },
            disenrolledOn: {
                isVisible: false
            },
            uploadedInsuranceOn: {
                isVisible: false
            },
            uploadedConsentFormOn: {
                isVisible: false
            },
            uploadedSedOn: {
                isVisible: false
            },
            uploadedIepOn: {
                isVisible: false
            },
            isSeeingTherapist: {
                isVisible: false
            },
        },
        filterModel: {
            items: []
        },
    },
    'clients_Yungeleit': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },

    },
    'clients_Professional': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },

    },
    'clients_Appointments': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },

    },
    'clients_Appointment': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'clients_Seeking': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'clients_History': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'employees_Availability': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'employees_Schedules': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'employees_Professional': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'employees_Appointments': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'employees_Appointment': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'employees_Potentials': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'employees_History': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'dashboard_seekingSchedule': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'dashboard_financialSchedule': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'dashboard_onHoldSchedule': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'dashboard_rejectedSchedule': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'dashboard_activeSchedule': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'dashboard_paymentTypeSchedule': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'dashboard_statusStepDuration': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'dashboard_clientsWithYungerleit': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'dashboard_clientsWithProfessionals': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'dashboard_clientScheduleStatus': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'dashboard_timesheet': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'dashboard_task': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'client_task': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'employee_task': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'client_progress': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'client_contact': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'client_todo': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'timesheet': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'yeshivas': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'yeshivas_contact': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'yeshivas_clients': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'users': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },
    'system_lists': {
        columnSettings: {

        },
        filterModel: {
            items: []
        },
    },


}

export const defaultQuickFilters = {
    home: {
        "ShowDisenrolled": 'false'
    },
    employee: {
        "ShowInactive": 'false',
        "ShowPending": 'false',
        "ShowNon-approved": 'false'

    },
    employee_professionals: {
        "ShowInactive": 'false',
        "ShowPending": 'false',
        "ShowNon-approved": 'false'
    },
    employee_mamidim: {
        "ShowInactive": 'false',
        "ShowPending": 'false',
        "ShowNon-approved": 'false'
    }
}

export const SYSTEM_VIEW_STRUCT = {
    viewName: 'Main',
    dataGridName: 'system',
    isDefault: false,
    columnSettings: [],
    filterSettings: []
}

export const PAYMENT_TYPE = {
    cash: 'cash',
    comfort: 'comfort',
    all: 'all'
}

export const ON_GOING_STATUS = {
    active: 'active',
    ended: 'ended',
    financial: 'financial',
    all: 'all'
}

export const DASHBAORD_ACTIVE_SCHEDULES_PAYEMENT_FILTERS = [
    {
        label: 'Cash',
        value: 'cash'
    },
    {
        label: 'Comfort',
        value: 'comfort'
    },
    {
        label: 'All',
        value: 'all'
    },
]

export const DASHBAORD_ACTIVE_SCHEDULES_ACTIVE_FILTERS = [
    {
        label: 'Active',
        value: 'active'
    },
    {
        label: 'Ended',
        value: 'ended'
    },
    {
        label: 'Financial',
        value: 'financial'
    },
    {
        label: 'All',
        value: 'all'
    },
]

export const YESHIVAS_MANAGE_TABS = [
    {
        label: 'General',
        value: 'general',
        isError: false
    },
    {
        label: 'Seder',
        value: 'seder',
        isError: false
    },
]

export const YUNGERMAN_MODAL_TABS = [
    {
        label: 'Schedule',
        value: 'schedule',
        isError: false
    },
    {
        label: 'Financial Info',
        value: 'financialInfo',
        isError: false
    },
    {
        label: 'Search Provider',
        value: 'searchProvider',
        isError: false
    },
    {
        label: 'Timesheet',
        value: 'timesheet',
        isError: false
    },
];

export const SCHEDULE_TYPE = {
    yungerman: 'yungerman',
    professional: 'professional',
    appointment: 'appointment',
    availability: 'availability',
    applicant: 'applicant'
}

export const dataGridFiltersOperatorTypes = {
    'isAnyOf': {
        operator: 'isAnyOf',
        valueType: 'array',
    },
    'is empty': {
        operator: 'is empty',
        valueType: 'boolean',
    },
    'is not empty': {
        operator: 'is not empty',
        valueType: 'boolean',
    },
    "=": {
        operator: '=',
        valueType: 'integer',
    },
    "!=": {
        operator: '!=',
        valueType: 'integer',
    },
    ">": {
        operator: '>',
        valueType: 'integer',
    },
    "=>": {
        operator: '=>',
        valueType: 'integer',
    },
    "<": {
        operator: '<',
        valueType: 'integer',
    },
    "=<": {
        operator: '=<',
        valueType: 'integer',
    },
    "contains": {
        operator: 'contains',
        valueType: 'string',
    },
    "equals": {
        operator: 'equals',
        valueType: 'string',
    },
    "starts with": {
        operator: 'starts with',
        valueType: 'string',
    },
    "ends with": {
        operator: 'ends with',
        valueType: 'string',
    },
    "is": {
        operator: 'is',
        valueType: 'boolen',
    },
    "is not": {
        operator: 'is  not',
        valueType: 'string',
    },
    "is after": {
        operator: 'is after',
        valueType: 'string',
    },
    "is on or after": {
        operator: 'is on or after',
        valueType: 'string',
    },
    "is before": {
        operator: 'is before',
        valueType: 'string',
    },
    "is on or before": {
        operator: 'is on or before',
        valueType: 'string',
    },

}


export const dataGridViewScreens = [
    'clients_Yungeleit',
    'clients_Professional',
    'clients_Appointments',
    'clients_Seeking',
    'clients_History',
    'employees_Availability',
    'employees_Schedules',
    'employees_Potentials',
    'employees_Professional',
    'employees_Appointment',
    'employees_Appointments',
    'clients_Appointment',
    'dashboard_seekingSchedule',
    'dashboard_financialSchedule',
    'dashboard_onHoldSchedule',
    'dashboard_rejectedSchedule',
    'dashboard_activeSchedule',
    'dashboard_paymentTypeSchedule',
    'dashboard_statusStepDuration',
    'dashboard_clientsWithYungerleit',
    'dasboard_onHoldSchedule',
    'dashboard_clientsWithProfessionals',
    'dashboard_clientScheduleStatus',
    'dashboard_timesheet',
    'dashboard_task',
    'system_lists'
];

export const YESHIVAS_SCHEDULE_DETAILS_STATE = {
    earlyMorning: { startTime: '', endTime: '', error: '', mode: 'read', isLoading: false },
    morning: { startTime: '', endTime: '', error: '', mode: 'read', isLoading: false },
    afternoon: { startTime: '', endTime: '', error: '', mode: 'read', isLoading: false },
    night: { startTime: '', endTime: '', error: '', mode: 'read', isLoading: false },
};

export const YESHIVAS_GENERAL_DETAILS_STATE = {
    basicInfo: {
        name: { value: '', error: '', isRequired: true },
        legalName: { value: '', error: '', isRequired: true },
        description: { value: '', error: '' },
        mode: 'read',
        isLoading: false
    },
    mainAddress: {
        address1: { value: '', error: '', isRequired: true },
        address2: { value: '', error: '' },
        aptPOBox: { value: '', error: '' },
        city: { value: '', error: '' },
        state: { value: '', error: '' },
        zip: { value: '', error: '' },
        neighborhood: { value: '', error: '' },
        mode: 'read',
        isLoading: false
    },
    legalAddress: {
        address1: { value: '', error: '', isRequired: true },
        address2: { value: '', error: '' },
        aptPOBox: { value: '', error: '' },
        city: { value: '', error: '' },
        state: { value: '', error: '' },
        zip: { value: '', error: '' },
        neighborhood: { value: '', error: '' },
        mode: 'read',
        isLoading: false
    },
    summerAddress: {
        address1: { value: '', error: '', isRequired: true },
        address2: { value: '', error: '' },
        aptPOBox: { value: '', error: '' },
        city: { value: '', error: '' },
        state: { value: '', error: '' },
        zip: { value: '', error: '' },
        neighborhood: { value: '', error: '' },
        mode: 'read',
        isLoading: false
    },
};