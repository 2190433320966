import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";
import * as constant from "../../../../../constants";
import baseStyles from '../../../../../assets/styles';
import useUnsavedChangesWarn from "../../../../../hooks/useUnsavedChangesWarn";
import useKeyTracker, { KeyEvent } from '../../../../../hooks/useKeyTracker';
import AddNoteForm, { validateAllFields } from './AddNoteForm';
import { getClientGeneralNotes } from "../../../../../api/notes"
import { strings } from '../../../../../_helpers/strings'
import { dateTime } from "../../../../../_helpers/datetime";

export default function AddNote(props) {
    const [note, setNote] = useState({});
    const baseClasses = baseStyles();

    const [valdiator_AddNoteForm, setValidator_AddNoteForm] = useState();

    const [loaderDialog, setLoaderDialog] = React.useState(false);

    const exploringId = useStoreState((state) => state.exploringId);
    const exploringType = useStoreState((state) => state.exploringType);

    const setLoading = useStoreActions((actions) => actions.setLoading);
    const endLoading = useStoreActions((actions) => actions.endLoading);

    const [changesHelper] = useUnsavedChangesWarn();
    const { Prompt, setDirty, setClean, isDirty, changedItemsIncludes, changedItems, addChange, removeChange, runRollbackAction, wConfirm, hasChanges } = changesHelper;

    useEffect(() => {
        resetAllData();
    }, []);

    function resetAllData() {
        setNote({
            title: "",
            description: "",
            startDate: new Date(),
            dueDate: new Date(),
            completedDate: null,
            assignee_contactId: null,
        })

        if (!_.isEmpty(props.editData)) {
            setNote(props.editData)
        }
        setClean();
    }
    useEffect(() => {
        if (!_.isEmpty(props.editData)) {
            setNote(props.editData)
        }
    }, [props.editData])

    const [errors, setErrors] = useState({})

    const saveNote = () => {
        const validate = validateAllFields(note);
        const isValid = validate.isValid;
        setErrors(validate.errors);

        if (isValid) {
            const saveNotePayload = {
                Type: '2',
                title: note.title,
                table: exploringType,
                createdBy_AccountId: note.assignee_ContactId,
                primaryId: note.primaryId ? note.primaryId : exploringId,
                NoteDescription: note.description,
                noteDate: new Date(),
            };



            let noteId = note.id ? note.id : -1;
            if (global.isEmpty(noteId))
                noteId = -1;

            if (global.fetchWrapper.inProgress) {
                closeModal();
                return;
            }
            setLoading();
            return global.fetchWrapper
                .post(constant.CMS_NOTES_SAVE + noteId, saveNotePayload)
                .then(
                    async (response) => {
                        console.log(response)

                        setClean();
                        closeModal(true);
                        await reloadNotes();
                    },
                    (error) => {
                        global.log("error", error);
                        global.AjaxFailToast();
                    }
                )

        }
    };

    const reloadNotes = () => {
        getClientGeneralNotes({
            table: 'clients',
            primaryId: props.clientData.id,
        }).then((response) => {
            console.log(response)
            props.setNotes(response);
        });
    };



    const timesheetTabShowShortcut = useKeyTracker({
        keyEvents: [{ key: 'Enter', ctrlKey: true /*, action: saveNote*/ }]
    });
    useEffect(() => {
        if (timesheetTabShowShortcut.keyExecutions.length > 0) {
            saveNote(note);
        }
    }, [timesheetTabShowShortcut.keyExecutions.length])

    const handleClose = (event, reason) => {
        if (reason && (reason == "backdropClick" || reason == "escapeKeyDown"))
            return;
        if (hasChanges() && !wConfirm(event)) {
            return;
        }
        closeModal();
    };
    const closeModal = (reloadData = false) => {
        props.onCloseModal();
        if (reloadData && typeof props.reloadData === 'function')
            props.reloadData();
        resetAllData();
        endLoading();
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={props.isOpenCreateModal}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle
                    className={baseClasses.dialogTitle}
                    id="max-width-dialog-title"
                >
                    {props.editData?.id > 0 ? "Edit" : "Add"} Note
                </DialogTitle>
                <DialogContent>
                    {loaderDialog ? (
                        <div>Loading...</div>
                    ) : (
                        <AddNoteForm
                            note={note}
                            setNote={setNote}
                            setDirty={setDirty}
                            setValidator={setValidator_AddNoteForm}
                            errors_prm={errors}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={saveNote} color="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
