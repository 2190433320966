import React, { useState, useEffect } from 'react';
import _ from "lodash";
import ContactSelectField from "../../../../ContactSelect/ContactSelectField";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import RichTextField from '../../../../UI/RichTextField';
import { strings } from '../../../../../_helpers/strings'
import {
    FormControl,
    FormHelperText,
    Grid,
    TextField
} from "@mui/material";
import useStyles from "../../Dialog/styles";
import baseStyles from '../../../../../assets/styles';

export const validateAllFields = (item) => {
    let isValid = true;
    let newErrors = {};
    if (!strings.getRichTextObjFromString(item.title).value) {
        isValid = false;
        newErrors.title = "please enter title"
    }
    if (!item.assignee_ContactId) {
        isValid = false;
        newErrors.assignee_ContactId = "task assignee is required"
    }
    if (item.completedDate && new Date(item.completedDate) < new Date(item.startDate)) {
        isValid = false;
        newErrors.completedDate = "Completed date of a task cannot be before its start"
    }
    return { isValid, errors: newErrors };
}

const AddNoteForm = (props) => {
    const { note, setNote, setDirty, setValidator, isFromProgress, errors_prm } = props;
    const classes = useStyles();
    const baseClasses = baseStyles();

    useEffect(() => {
        setNote((prev) => { return { ...prev, note } });
    }, [])

    const [errors, setErrors] = useState({})
    useEffect(() => {
        if (!!errors_prm)
            setErrors(errors_prm);
        else {
            //kind of a hack should be changed
            if (typeof setValidator === 'function')
                setValidator(() => (item) => {
                    const validation = validateAllFields(item);
                    setErrors(validation.errors);
                    return validation.isValid;
                })
        }
    }, [errors_prm, setValidator]);

    const updateNote = (name, value) => {
        setNote((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const updateErrors = (name, errMsg) => {
        setErrors((prev) => {
            return {
                ...prev,
                [name]: errMsg
            }
        })
    }

    return (<div>
        {note.creator ? (
            <div>
                <p>Created By: {note.creator}</p>
            </div>
        ) : (<div></div>)
        }

        <Grid
            container
            className={baseClasses.grid}
            alignItems="flex-start"
            spacing={2}
        >
            {!isFromProgress &&
                <Grid item xs={12}>
                    <div>
                        <FormControl fullWidth>
                            <RichTextField
                                value={note.title}
                                label={"Title"}
                                id="addnote-title"
                                variant="outlined"
                                onChange={(event) => {
                                    if (typeof setDirty === 'function')
                                        setDirty();
                                    if (!_.isEmpty(event.target.value)) {
                                        updateErrors('title', '');
                                    }
                                }}
                                setState={(val) => { updateNote('title', val) }}
                                aria-describedby="my-helper-text"
                            />
                            {errors.title && (
                                <FormHelperText
                                    className={baseClasses.error}
                                    style={{ color: 'red' }}
                                    id="my-helper-text"
                                >
                                    {errors.title}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>
                </Grid>

            }


            <Grid item xs={12}>
                <div>
                    <ContactSelectField
                        label="Select Assignee"
                        userRole="any"
                        showUserRoles={true}
                        allowNew={false}
                        editData={note.assignee_ContactId}
                        editDataType="id"
                        onChange={(contact) => {
                            if (typeof setDirty === 'function')
                                setDirty();
                            updateNote('assignee_ContactId', contact?.id)
                        }}
                    ></ContactSelectField>
                    {errors.assignee_ContactId && (
                        <FormHelperText
                            className={baseClasses.error}
                            style={{ color: 'red' }}
                            id="my-helper-text"
                        >
                            {errors.assignee_ContactId}
                        </FormHelperText>
                    )}
                </div>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <FormControl fullWidth>
                        <RichTextField
                            id="my-input"
                            label="Description"
                            multiline
                            rows={isFromProgress ? 2 : 4}
                            value={note.description}
                            onChange={(event) => {
                                if (typeof setDirty === 'function')
                                    setDirty();
                                if (!_.isEmpty(event.target.value)) {
                                    updateErrors('description', "");
                                }

                            }}
                            setState={(val) => { updateNote('description', val) }}
                        />


                        {errors.description && (
                            <FormHelperText
                                className={baseClasses.error}
                                style={{ color: 'red' }}
                                id="my-helper-text"
                            >
                                {errors.description}
                            </FormHelperText>
                        )}
                    </FormControl>
                </div>
            </Grid>
        </Grid>
    </div>)
}

export default AddNoteForm;