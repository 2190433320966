import { Grid, Tooltip, Typography, Divider, Button, Card, CardContent, TextField } from "@mui/material";
import React, { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import TextFormattingWrapper from '../../../../../utils/TextFormattingWrapper'










const NoteCard = ({ note, onClickOpen, onEdit, onDelete, onSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedDescription, setEditedDescription] = useState(note.noteDescription);

    const handleSave = async () => {
        const updatedNote = {
            ...note,
            noteDescription: editedDescription,
        };

        if (onSave) {
            console.log(updatedNote)
            setIsEditing(false);
            await onSave(updatedNote);
        }

        setIsEditing(false);
    };

    const handleCancel = () => {
        setEditedDescription(note.noteDescription);
        setIsEditing(false);
    };

    return (
        <div
            className="note-row"
            style={{
                overflow: "hidden",
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 0",
                cursor: "pointer",
            }}
            onClick={onClickOpen}
        >
            {/* Left column: Name and Date */}
            <div
                style={{
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "40%",
                }}
            >
                <Typography variant="body2" style={{ fontSize: 12 }}>
                    <strong>By:</strong>{" "}
                    <span style={{ color: "gray", fontSize: 12 }}>{note.createdBy}</span>
                </Typography>
                <Typography variant="body2" style={{ fontSize: 12 }}>
                    <strong>On:</strong>{" "}
                    <span style={{ color: "gray", fontSize: 12 }}>
                        {global._DateField(note.noteDate)}
                    </span>
                </Typography>
            </div>

            {/* Right column: Description and Edit/Delete icons */}
            <div
                style={{
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "flex-start",
                }}
                onClick={onClickOpen}
            >
                {!isEditing ? (
                    <>
                        {/* View mode */}
                        <div
                            style={{
                                flex: 1,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                fontSize: 14,
                                color: "gray",
                            }}
                        >
                            <TextFormattingWrapper value={note.noteDescription} />
                        </div>
                        <EditIcon
                            style={{
                                fontSize: "15px",
                                marginLeft: "5px",
                                cursor: "pointer",
                                color: "gray",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsEditing(true);
                            }}
                        />
                        <DeleteIcon
                            style={{
                                fontSize: "15px",
                                marginLeft: "5px",
                                cursor: "pointer",
                                color: "gray",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(note.id);
                            }}
                        />
                    </>
                ) : (
                    <>
                        {/* Edit mode */}
                        <div style={{ flex: 1 }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={editedDescription}
                                onChange={(e) => setEditedDescription(e.target.value)}
                                multiline
                                maxRows={3}
                            />
                        </div>
                        <Button
                            variant="contained"
                            size="small"
                            style={{ marginLeft: "5px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleSave();
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            style={{ marginLeft: "5px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCancel();
                            }}
                        >
                            Cancel
                        </Button>
                    </>
                )}
            </div>
        </div>
    );

}
export default NoteCard;

