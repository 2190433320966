import _ from "lodash";
import {
    CMS_GENERAL_NOTES_DELETE, CLIENT_GENERAL_NOTES, CMS_NOTES_SAVE
} from "../constants";



export let getClientGeneralNotes = (queryObj) => {
    let query = "?table=" + (queryObj.table ? queryObj.table : '') +
        "&primaryId=" + (queryObj.primaryId ? queryObj.primaryId : '') +
        "&nocache=" + new Date().getTime();

    return new Promise((resolve, reject) => {
        global.fetchWrapper.get(
            `${CLIENT_GENERAL_NOTES}${queryObj.table}/${queryObj.primaryId}`
        )
            .then((data) => {

                const notesData = Array.isArray(data) ? data : data.result;

                if (notesData && notesData.length > 0) {
                    const notes = notesData.map((item, index) => ({
                        id: item.id ? item.id : index,
                        type: item.type ? item.type : "-",
                        table: item.table ? item.table : "-",
                        primaryId: item.primaryId,
                        noteDate: item.noteDate ? global._DateField(item.noteDate) : null,
                        title: item.title ? item.title : "-",
                        noteDescription: item.noteDescription ? item.noteDescription : null,
                        nextStep: item.nextStep ? item.nextStep : "-",
                        creatorRole: item.creatorRole ? item.creatorRole : "-",
                        noteTemplateId: item.noteTemplateId ? item.noteTemplateId : null,
                        createdBy: item.account
                            ? `${item.account.firstName} ${item.account.lastName}`
                            : "-",
                    }));
                    resolve(notes);
                } else {
                    console.log("No notes data found");
                    resolve([]);
                }
            })
    })
};


export let deleteNote = (noteId) => {
    if (global.isEmpty(noteId)) {
        return;
    }

    if (global.fetchWrapper.inProgress) {
        global.log("info", "Request in progress, please wait");
        return;
    }


    return global.fetchWrapper
        .post(`${CMS_GENERAL_NOTES_DELETE}/${noteId}`)
        .then(
            (response) => {
                global.log("success", "Note deleted successfully");
            },
            (error) => {
                global.log("error", error);
                global.AjaxFailToast();
            }
        )
        .finally(() => {

        });
};

// right now the function is in the model but should move it here


// this is for editing a note 
export let saveNote = (noteToSave,) => {
    // const validate = validateAllFields(note);
    // const isValid = validate.isValid;
    // setErrors(validate.errors);








    if (global.fetchWrapper.inProgress) {
        return;
    }
    // setLoading();
    return global.fetchWrapper
        // .post(constant.TODO_SAVE + noteId, saveNotePayload)
        .post(CMS_NOTES_SAVE + noteToSave.id, noteToSave)
        .then(
            (response) => {
                // setClean();
                // closeModal(true);
            },
            (error) => {
                global.log("error", error);
                //closeModal();
                global.AjaxFailToast();
            }
        );

};